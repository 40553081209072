<template>
  <TopMainTitle/>
  <div class="aes_textarea">
    <div style="margin-top: 40px">
      <span class="el_left_cls">当前时间:</span>
      <el-input
          v-model="nowTime"
          type="input"
          style="width: 20%"
          class="el_right_cls"/>

      <span class="el_left_cls">当前时间戳:</span>
      <el-input
          v-model="nowTimeStamp"
          type="input"
          style="width: 20%"
          oninput="value=value.replace(/[^0-9.]/g,'')"
          class="el_right_cls"/>
      <el-button class="el_right_cls" type="success" v-if="!startOrStopFlag" @click="startFun">开始</el-button>
      <el-button class="el_right_cls" type="danger" v-if="startOrStopFlag" @click="stopFun">停止</el-button>
    </div>
    <div style="margin-top: 30px">
      <span class="el_left_cls">北京时间:</span>
      <el-input
          v-model="beijingTime"
          type="input"
          style="width: 20%"
          class="el_right_cls"/>
      <el-select
          v-model="beijingTimeToTimeStampOptionsValue"
          placeholder="Select"
          style="width: 101px"
          class="el_right_cls">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-button class="el_right_cls" type="primary" @click="beijingTimeToTimeStampFun">北京时间->时间戳</el-button>
      <el-input
          v-model="beijingTimeToTimeStamp"
          type="input"
          style="width: 20%"
          oninput="value=value.replace(/[^0-9.]/g,'')"
          class="last_cls"/>

      <el-icon color="#5D9FFF" @click="copyBeijingTimeToTimeStamp"><CopyDocument /></el-icon>
    </div>
    <div style="margin-top: 30px">
      <span class="el_left_cls" style="padding-left: 16px">时间戳:</span>
      <el-input
          v-model="timeStamp"
          type="input"
          style="width: 20%"
          oninput="value=value.replace(/[^0-9.]/g,'')"
          class="el_right_cls"/>
      <el-select
          v-model="timeStampToBeijingTimeOptionsValue"
          placeholder="Select"
          style="width: 101px"
          class="el_right_cls">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-button class="el_right_cls" type="primary" @click="timeStampToBeijingTimeFun">时间戳->北京时间</el-button>
      <el-input
          v-model="timeStampToBeijingTime"
          type="input"
          style="width: 20%"
          oninput="value=value.replace(/[^0-9.]/g,'')"
          class="last_cls"/>

      <el-icon color="#5D9FFF" @click="copyTimeStampToBeijingTime"><CopyDocument /></el-icon>
    </div>

    <div class="div_bottom_cls">
      <span style="color: black;font-weight: bolder">各语言获取当前时间戳</span>
      <div class="div-border-card-cls">
        <el-table :data="tableData" stripe border style="width: 100%">
          <el-table-column prop="language" label="语言" width="150" />
          <el-table-column prop="code" label="代码" width="780%" />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>

import TopMainTitle from "@/components/TopMainTitle.vue";
import {copyText} from "vue3-clipboard";

export default {
  name: "timestampConversion",
  components: {TopMainTitle},
  data() {
    return {
      nowTime:'',
      nowTimeStamp:'',
      beijingTime:'',
      timeStamp:0,
      beijingTimeToTimeStamp:'',
      timeStampToBeijingTime:'',
      beijingTimeToTimeStampOptionsValue:'second',
      timeStampToBeijingTimeOptionsValue:'second',
      options:[
        {
          value: 'second',
          label: '秒(s)',
        },
        {
          value: 'millisecond',
          label: '毫秒(ms)',
        }
      ],
      timer: null,
      startOrStopFlag:true,
      tableData:[
        {
          language: 'Java',
          code: '(int) (System.currentTimeMillis() / 1000)',
        },
        {
          language: 'MYSQL',
          code: 'select *from `date` where DATEDIFF(FROM_UNIXTIME(unix_timestamp),NOW())=0',
        },
        {
          language: 'PHP',
          code: 'echo time();//秒时间戳 ',
        },
        {
          language: 'JavaScript',
          code: 'Math.round(new Date() / 1000)',
        },
        {
          language: 'Shell',
          code: 'date +%s',
        },
        {
          language: 'SQLite',
          code: 'SELECT strftime(\'%s\', \'now\')',
        }
      ]
    };
  },
  mounted() {
    this.startFun();
    this.getNowBeijingTimeAndTimeStamp();
  },
  methods: {
    copyBeijingTimeToTimeStamp(){
      if (this.beijingTimeToTimeStamp === '') {
        this.$message.error('请先转换后再复制');
        return;
      }
      copyText(this.beijingTimeToTimeStamp, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    copyTimeStampToBeijingTime(){
      if (this.timeStampToBeijingTime === '') {
        this.$message.error('请先转换后再复制');
        return;
      }
      copyText(this.timeStampToBeijingTime, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    beijingTimeToTimeStampFun(){
      this.beijingTimeToTimeStamp = '';
      if(this.beijingTime === ''){
        this.$message.warning('北京时间为空，默认获取当前时间戳(毫秒)');
        this.beijingTimeToTimeStamp = new Date().getTime();
      }else{
        if(this.beijingTimeToTimeStampOptionsValue === 'second'){
          this.beijingTimeToTimeStamp = new Date(this.beijingTime).getTime()/1000;
        }else{
          this.beijingTimeToTimeStamp = new Date(this.beijingTime).getTime();
        }
      }
    },
    timeStampToBeijingTimeFun(){
      this.timeStampToBeijingTime = '';
      let timeStampLength = (typeof (this.timeStamp) === "number") ? this.timeStamp.toString().length: this.timeStamp.length;
      if(timeStampLength !== 10 && timeStampLength !== 13){
        this.$message.error('时间戳格式不正确');
        return;
      }
      if(timeStampLength === 13){
        this.timeStampToBeijingTime = this.queryNowBeijingTimeAndTimeStamp(new Date((typeof (this.timeStamp) === "number")?this.timeStamp:parseInt(this.timeStamp)));
      }else{
        this.timeStampToBeijingTime = this.queryNowBeijingTimeAndTimeStamp(new Date(this.timeStamp * 1000));
      }
    },
    startFun(){
      if(this.timer !== null){
        this.startOrStopFlag = !this.startOrStopFlag;
      }
      this.timer = setInterval(() => {
        this.updateNowTimeAndTimeStamp();
      },1000);
    },
    stopFun(){
      this.startOrStopFlag = !this.startOrStopFlag;
      clearInterval(this.timer);
    },
    updateNowTimeAndTimeStamp(){
      this.nowTimeStamp = new Date().getTime();
      this.nowTime = this.queryNowBeijingTimeAndTimeStamp(new Date());
    },
    getNowBeijingTimeAndTimeStamp(){
      this.beijingTime = this.queryNowBeijingTimeAndTimeStamp(new Date());
      this.timeStamp = new Date().getTime();
    },
    queryNowBeijingTimeAndTimeStamp(date){
      let y = date.getFullYear().toString();
      let M = (date.getMonth() + 1).toString().padStart(2, '0');
      let d = date.getDate().toString().padStart(2, '0');
      let h = date.getHours().toString().padStart(2, '0');
      let m = date.getMinutes().toString().padStart(2, '0');
      let s = date.getSeconds().toString().padStart(2, '0');
      return `${y}-${M}-${d} ${h}:${m}:${s}`;
    }
  }
}
</script>

<style scoped lang="scss">
.aes_textarea {
  :deep(.el-textarea__inner) {
    height: calc(100vh - 205px);
  }

  margin: 10px 20px 0 20px;

  .el_left_cls{
    margin-right: 10px;
  }
  .el_right_cls{
    margin-right: 20px;
  }
  .last_cls{
    margin-right: 10px;
  }
  .div_bottom_cls{
    margin-top: 40px;
  }
  .div-border-card-cls{
    margin-top: 10px;
  }
}
</style>