import Cookies from 'js-cookie'
// 用户令牌
const TokenKey = 'Admin-Token'
export function getToken() {
    return Cookies.get(TokenKey)
}
export function setToken(token) {
    return Cookies.set(TokenKey, token)
}
export function removeToken() {
    return Cookies.remove(TokenKey)
}
// 用户路由
const RouterPage = 'Admin-Router'
export function getRouter() {
    return Cookies.get(RouterPage)
}
export function setRouter(router) {
    return Cookies.set(RouterPage, router)
}
export function remove() {
    return Cookies.remove(RouterPage)
}

