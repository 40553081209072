<template>
  <div>
    <TopMainTitle/>
    <div class="aes_textarea">
      <div>
        <span class="aes-tabs-pane-span">加密前的字符串</span>
        <el-input
            v-model="decryValue"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 6}"
            class="aes-tabs-pane-input"
            resize="none"
            placeholder="请输入需要加密的字符串"/>

      </div>
      <div style="text-align: center;">
        <el-button style="width: 20%" type="primary" @click="md5Encry">加密</el-button>
        <el-button style="width: 20%;color: white" color="#09BF8C" @click="copyDecry">复制结果</el-button>
        <el-button style="width: 20%" type="warning" @click="del">清空</el-button>
      </div>
      <div class="encry-decry-result">
        <span class="aes-tabs-pane-span bloder">加密后的字符串</span>
        <el-input
            v-model="encryValue"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 6}"
            class="aes-tabs-pane-input"
            resize="none"
            placeholder="加密后的字符串"/>
      </div>
    </div>
  </div>
</template>

<script>
import { md5 } from 'js-md5';
import {copyText} from "vue3-clipboard";
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "md5Encry",
  components: {TopMainTitle},
  data() {
    return {
      itemName:"",
      decryValue: "",
      encryValue: ""
    };
  },
  methods: {
    md5Encry() {
      try {
        const value = md5(this.decryValue);
        this.encryValue = '32位大写：'+value.toUpperCase()+'\n32位小写：'+value.toLowerCase()+'\n16位大写：'+value.substring(8, 24).toUpperCase()+'\n16位小写：'+value.substring(8, 24).toLowerCase();
      } catch (e) {
        this.$message.error('格式错误');
      }
    },
    copyDecry() {
      if(this.encryValue === ''){
        this.$message.error('请先计算加密数据后再复制');
        return;
      }
      copyText(this.encryValue, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    del(){
      this.decryValue = '';
      this.encryValue = '';
    }
  }
}
</script>

<style scoped lang="scss">
.aes_textarea{
  :deep(.el-textarea__inner){
    height: calc(100vh - 205px);
  }
  margin: 0 20px 0 20px;
  .aes-tabs-pane-span{
    font-size: 13px;
  }
  .aes-tabs-pane-input{
    margin: 5px 0 25px 0;
  }
  .bloder{
    font-weight: bolder;
    font-size: 16px;
  }
  .encry-decry-result{
    margin-top: 25px;
  }
  .aes-type{
    padding-right: 10px;
  }
}
.main-title{
  /*padding: 5px 0 0 10px;*/
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

//.aes_textarea{
//  :deep(.el-tabs__item.is-top){
//    width: 200px;
//    font-size: 20px;
//    color: red;
//  }
//  :deep(.el-tabs__active-bar.is-top){
//    background-color: green;
//  }
//}

</style>