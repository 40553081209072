<template>
  <div>
    <TopMainTitle/>
    <el-row>
      <el-col :span="15">
        <div class="text_word">
          <el-input class="md5_textarea" type="textarea" resize="none" placeholder="请输入需要统计的文本"
                    v-model="textWord"></el-input>
        </div>
      </el-col>

      <el-col :span="9">
        <div class="text_word_count">
          <el-table :data="tableData" border stripe style="width: 100%" :show-header="false">
            <el-table-column prop="text" width="130" />
            <el-table-column prop="count" width="220" />
          </el-table>
        </div>
      </el-col>

    </el-row>
    <div style="text-align: center;margin-top: 10px;">
      <el-button style="width: 20%" type="primary" @click="countFun">统计</el-button>
      <el-button style="width: 20%" type="warning" @click="delValue">清空</el-button>
    </div>
  </div>
</template>

<script>

import {Plus} from "@element-plus/icons-vue";
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "wordCount",
  components: {Plus,TopMainTitle},
  data() {
    return {
      textWord:'',
      tableData:[]
    };
  },
  mounted() {
    this.tableDataInit();
  },
  methods: {
    tableDataInit(){
      this.tableData = [
        {text: '总字数', count: 0+' （个）'},
        {text: '中文字数', count: 0+' （个）'},
        {text: '中文标点', count: 0+' （个）'},
        {text: '字母个数', count: 0+' （个）'},
        {text: '英文标点', count: 0+' （个）'},
        {text: '单词个数', count: 0+' （个）'},
        {text: '数字个数', count: 0+' （个）'},
        {text: '数字组', count: 0+' （个）'},
        {text: '总行数', count: 0+' （行）'},
      ]
    },
    countFun(){
      var content = this.textWord.replace(/\r\n/g, "\n"); // 完整的内容
      var str = content.replace(/\n/g, ''); // 纯粹字符
      var Chinese_characters = content.match(/[\u4e00-\u9fa5]/g) || []; // 中文字符
      var phrase = content.match(/\b\w+\b/g) || []; // 数字+字母
      var group_number = content.match(/\b\d+\b/g) || []; // 数字
      var letter = str.match(/[A-Za-z]/g) || []; // 英文字母
      var number = str.match(/[0-9]/g) || []; // 数字
      // 英文标点
      var half_punctuation = str.match(/[|\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?]/g) || [];

      // 中文字符总数
      var Chinese_all = 0;
      for (var i = 0; i < str.length; i++) {
        var c = str.charAt(i);
        if (c.match(/[^\x00-\xff]/)) Chinese_all++;
      }

      // 计算段落数
      var part = 0;
      var s_ma = content.split("\n");
      for (var i = 0; i < s_ma.length; i++) {
        if (s_ma[i].length > 0) part++;
      }
      this.tableData = [
        {text: '总字数', count: str.length+' （个）'},
        {text: '中文字数', count: Chinese_characters.length+' （个）'},
        {text: '中文标点', count: Chinese_all - Chinese_characters.length+' （个）'},
        {text: '字母个数', count: letter.length+' （个）'},
        {text: '英文标点', count: half_punctuation.length+' （个）'},
        {text: '单词个数', count: phrase.length - group_number.length+' （个）'},
        {text: '数字个数', count: number.length+' （个）'},
        {text: '数字组', count: group_number.length+' （个）'},
        {text: '总行数', count: part+' （行）'},
      ];
    },
    delValue() {
      this.textWord = '';
      this.tableDataInit();
    },
  }
}
</script>

<style scoped lang="scss">
.md5_textarea{
  :deep(.el-textarea__inner){
    height: calc(100vh - 205px);
    font-family: inherit;
  }
}
.main-title{
  /*padding: 5px 0 0 10px;*/
  padding-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.text_word{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 10px;
  height: calc(100vh - 205px);
  font-family: inherit;
  border: 1px solid #DDDFE6;
  border-radius: 4px;
  &:hover{
    border: 1px solid #C8CBD2;
  }
}
.text_word_count{
  display: flex;
  justify-content: center;
  margin: 10px 10px 0 10px;
  height: calc(100vh - 205px);
  font-family: inherit;
  border-radius: 4px;
}
.id-card-uploader {
  display: flex;
  flex-direction: column;
}

.avatar-uploader {
  :deep(.el-upload) {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
  }
}

.avatar-uploader :deep(.el-upload:hover) {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.avatar {
  width: 230px;
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>