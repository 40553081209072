<template>
  <div>
    <TopMainTitle/>
    <el-row>
      <el-col :span="12">
        <div style="padding: 10px">
          <el-input class="md5_textarea" type="textarea" resize="none" placeholder="请输入需要转换为二维码的字符串" v-model="qrCodeStr"></el-input>
        </div>
      </el-col>

      <el-col :span="12">
        <div class="generate_qr_code">
          <a class="g_img" style="color: #959393;display: flex;justify-content: center;align-items: center"><span style="color: red;padding-right: 5px;padding-top: 3px">*</span>生成的二维码<span style="font-size: 10px">（右击“图片另存为”保存到本地）</span></a>
          <div style="display: flex;justify-content: center;" v-show="imageIsShow" ref="qrcodejs2"/>
        </div>
      </el-col>

    </el-row>
    <div style="text-align: center;margin-top: 10px;">
      <el-button style="width: 20%" type="primary" @click="conversion">转换</el-button>
      <el-button style="width: 20%" type="warning" @click="delValue">清空</el-button>
    </div>
  </div>
</template>

<script>

import QRCode from 'qrcodejs2'
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "generateQrCode",
  components: {TopMainTitle},
  data() {
    return {
      itemName:"",
      qrCodeStr:'',
      qrCodeValue:'',
      imageIsShow: false,
      qrcode:null,
    };
  },
  methods: {
    conversion() {
      debugger;
      this.imageIsShow=true;
      this.$refs.qrcodejs2.innerHTML = '';
      this.qrcode = new QRCode(this.$refs.qrcodejs2, {
        text: this.qrCodeStr,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
    },
    delValue() {
      this.qrCodeStr = '';
      this.qrCodeValue='';
      this.imageIsShow=false;
    },
  }
}
</script>

<style scoped lang="scss">
.md5_textarea{
  :deep(.el-textarea__inner){
    height: calc(100vh - 205px);
    font-family: inherit;
  }
}
.main-title{
  /*padding: 5px 0 0 10px;*/
  padding-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.generate_qr_code{
  margin: 10px 10px 0 0;
  padding: 10px 10px 10px 0;
  height: calc(100vh - 205px);
  font-family: inherit;
  border: 1px solid #DDDFE6;
  &:hover{
    border: 1px solid #C8CBD2;
  }
}
</style>