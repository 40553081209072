import { createRouter, createWebHistory } from 'vue-router'
import jsonFormatter from "@/views/developmentTool/jsonFormatter.vue";
import MainContent from "@/components/MainContent.vue";
import md5Encry from "@/views/encryDecry/md5Encry.vue";
import aesEncryDecry from "@/views/encryDecry/aesEncryDecry.vue";
import jsonCompressEscape from "@/views/developmentTool/jsonCompressEscape.vue";
import desEncryDecry from "@/views/encryDecry/desEncryDecry.vue";
import escapeCodeDecode from "@/views/codeDecode/escapeCodeDecode.vue";
import base64CodeDecode from "@/views/codeDecode/base64CodeDecode.vue";
import randomPasswordGeneration from "@/views/encryDecry/randomPasswordGeneration.vue";
import shaEncry from "@/views/encryDecry/shaEncry.vue";
import rsaEncryDecry from "@/views/encryDecry/rsaEncryDecry.vue";
import base64ToImage from "@/views/codeDecode/base64ToImage.vue";
import imageToBase64 from "@/views/codeDecode/imageToBase64.vue";
import generateQrCode from "@/views/imageProcessing/generateQrCode.vue";
import uuidGeneration from "@/views/developmentTool/uuidGeneration.vue";
import timestampConversion from "@/views/developmentTool/timestampConversion.vue";
import imageRecognitionColor from "@/views/imageProcessing/imageRecognitionColor.vue";
// import textComparison from "@/views/textTool/textComparison.vue";
import textReplaceAll from "@/views/textTool/textReplaceAll.vue";
import simpleAndTraditional from "@/views/textTool/simpleAndTraditional.vue";
import textToPinYin from "@/views/textTool/textToPinYin.vue";
import textToUnicode from "@/views/textTool/textToUnicode.vue";
import letterUpperLower from "@/views/textTool/letterUpperLower.vue";
import wordCount from "@/views/textTool/wordCount.vue";
import colorConversion from "@/views/colorTool/colorConversion.vue";
import shelfLifeCalculator from "@/views/calculateTool/shelfLifeCalculator.vue";
import nowYearHoliday from "@/views/calculateTool/nowYearHoliday.vue";
import twentyFourSolarTerms from "@/views/calculateTool/twentyFourSolarTerms.vue";
// import idCardCheck from "@/views/calculateTool/idCardCheck.vue";
// import xmlFormatter from "@/views/developmentTool/xmlFormatter.vue";

const routes = [
    {
        path: '/',
        name: 'MainContent',
        component: MainContent,
    },
    {
        path: '/jsonFormatter',
        name: 'jsonFormatter',
        component: jsonFormatter
    },
    {
        path: '/json',
        name: 'json',
        component: jsonFormatter
    },
    {
        path: '/md5Encry',
        name: 'md5Encry',
        component: md5Encry
    },
    {
        path: '/md5',
        name: 'md5',
        component: md5Encry
    },
    {
        path: '/aesEncryDecry',
        name: 'aesEncryDecry',
        component: aesEncryDecry
    },
    {
        path: '/jsonCompressEscape',
        name: 'jsonCompressEscape',
        component: jsonCompressEscape
    },
    {
        path: '/desEncryDecry',
        name: 'desEncryDecry',
        component: desEncryDecry
    },
    {
        path: '/escapeCodeDecode',
        name: 'escapeCodeDecode',
        component: escapeCodeDecode
    },
    {
        path: '/base64CodeDecode',
        name: 'base64CodeDecode',
        component: base64CodeDecode
    },
    {
        path: '/randomPasswordGeneration',
        name: 'randomPasswordGeneration',
        component: randomPasswordGeneration
    },
    {
        path: '/shaEncry',
        name: 'shaEncry',
        component: shaEncry
    },
    {
        path: '/rsaEncryDecry',
        name: 'rsaEncryDecry',
        component: rsaEncryDecry
    },
    {
        path: '/base64ToImage',
        name: 'base64ToImage',
        component: base64ToImage
    },
    {
        path: '/image',
        name: 'image',
        component: base64ToImage
    },
    {
        path: '/imageToBase64',
        name: 'imageToBase64',
        component: imageToBase64
    },
    {
        path: '/base64',
        name: 'base64',
        component: imageToBase64
    },
    {
        path: '/generateQrCode',
        name: 'generateQrCode',
        component: generateQrCode
    },
    {
        path: '/qrCode',
        name: 'qrCode',
        component: generateQrCode
    }/*,
    {
        path: '/xmlFormatter/:itemName/:itemUrl',
        name: 'xmlFormatter',
        component: xmlFormatter
    }*/,
    {
        path: '/uuidGeneration',
        name: 'uuidGeneration',
        component: uuidGeneration
    },
    {
        path: '/timestampConversion',
        name: 'timestampConversion',
        component: timestampConversion
    },
    {
        path: '/imageRecognitionColor',
        name: 'imageRecognitionColor',
        component: imageRecognitionColor
    },
    /*{
        path: '/textComparison',
        name: 'textComparison',
        component: textComparison
    }*/
    {
        path: '/textReplaceAll',
        name: 'textReplaceAll',
        component: textReplaceAll
    },
    {
        path: '/simpleAndTraditional',
        name: 'simpleAndTraditional',
        component: simpleAndTraditional
    },
    {
        path: '/textToPinYin',
        name: 'textToPinYin',
        component: textToPinYin
    },
    {
        path: '/textToUnicode',
        name: 'textToUnicode',
        component: textToUnicode
    },
    {
        path: '/letterUpperLower',
        name: 'letterUpperLower',
        component: letterUpperLower
    },
    {
        path: '/wordCount',
        name: 'wordCount',
        component: wordCount
    },
    {
        path: '/colorConversion',
        name: 'colorConversion',
        component: colorConversion
    },
    {
        path: '/shelfLifeCalculator',
        name: 'shelfLifeCalculator',
        component: shelfLifeCalculator
    }/*,
    {
        path: '/idCardCheck',
        name: 'idCardCheck',
        component: idCardCheck
    }*/,
    {
        path: '/nowYearHoliday',
        name: 'nowYearHoliday',
        component: nowYearHoliday
    },
    {
        path: '/twentyFourSolarTerms',
        name: 'twentyFourSolarTerms',
        component: twentyFourSolarTerms
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
