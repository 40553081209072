<template>
  <div>
    <TopMainTitle/>
    <div class="aes_textarea">
      <div class="top_area">
        <div class="color_selector_border">
          <span>颜色选择器:</span>
          <div class="color_selector" :style="{backgroundColor: sketchBgColor}"
               @click="changeShowSketch"></div>
        </div>
        <Sketch
            style="position: absolute; z-index: 1"
            v-model="colors"
            v-show="showSketch"
            presetColors=""
            disableAlpha="false"
            @changButton="changSketchButton"
        />
      </div>
      <div>
        <div class="value_area">
          <el-input
              v-model="hslValue"
              style="max-width: 400px;padding-right: 20px"
          >
            <template #prepend>HSL</template>
          </el-input>
          <el-input
              v-model="hsvValue"
              style="max-width: 400px;padding-right: 20px"
          >
            <template #prepend>HSV</template>
          </el-input>
          <el-input
              v-model="hexValue"
              style="max-width: 400px"
          >
            <template #prepend>HEX</template>
          </el-input>
        </div>

        <div class="value_area">
          <el-input
              v-model="cmykValue"
              style="max-width: 400px;padding-right: 20px"
          >
            <template #prepend>CMYK</template>
          </el-input>
          <el-input
              v-model="rgbValue"
              style="max-width: 400px;padding-right: 20px"
          >
            <template #prepend>RGB</template>
          </el-input>
          <el-input
              v-model="keywordValue"
              style="max-width: 400px;"
          >
            <template #prepend>Color name</template>
          </el-input>
        </div>

        <div class="value_area">
          <el-input
              v-model="labValue"
              style="max-width: 400px;padding-right: 20px"
          >
            <template #prepend>CIE Lab</template>
          </el-input>
          <el-input
              v-model="ansi16Value"
              style="max-width: 400px;padding-right: 20px"
          >
            <template #prepend>Ansi16</template>
          </el-input>
          <el-input
              v-model="ansi256Value"
              style="max-width: 400px;"
          >
            <template #prepend>Ansi256</template>
          </el-input>
        </div>

        <div class="value_area">
          <el-input
              v-model="hwbValue"
              style="max-width: 400px;;padding-right: 20px"
          >
            <template #prepend>HWB</template>
          </el-input>
          <el-input
              v-model="hcgValue"
              style="max-width: 400px;padding-right: 20px"
          >
            <template #prepend>HCG</template>
          </el-input>
          <el-input
              v-model="xyzValue"
              style="max-width: 400px;"
          >
            <template #prepend>XYZ</template>
          </el-input>
        </div>
      </div>

      <div style="text-align: center;margin-top: 20px">
        <el-button style="width: 20%" type="warning" @click="del">重置</el-button>
      </div>

    </div>
  </div>
</template>

<script>
import {copyText} from 'vue3-clipboard'
import {Sketch} from '@ans1998/vue3-color';
import convert from 'color-convert';
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "colorConversion",
  components: {TopMainTitle,Sketch},
  data() {
    return {
      hslValue: '',
      hsvValue: '',
      hwbValue: '',
      cmykValue: '',
      xyzValue: '',
      keywordValue: '',
      labValue: '',
      ansi16Value: '',
      ansi256Value: '',
      hexValue: '',
      hcgValue: '',
      appleValue: '',
      rgbValue:'',

      colors: "#000000",
      colorsRgb: "0,0,0,1",
      sketchBgColor: "#000000",
      showSketch: false
    };
  },
  mounted() {
    this.changeRgbOrOther();
  },
  methods: {
    changeRgbOrOther(){
      const rgbArray = this.colorsRgb.split(',').map(Number);
      this.hslValue = convert.rgb.hsl(rgbArray);
      this.hsvValue = convert.rgb.hsv(rgbArray);
      this.hwbValue = convert.rgb.hwb(rgbArray);
      this.cmykValue = convert.rgb.cmyk(rgbArray);
      this.xyzValue = convert.rgb.xyz(rgbArray);
      this.keywordValue = convert.rgb.keyword(rgbArray);
      this.labValue = convert.rgb.lab(rgbArray);
      this.ansi16Value = convert.rgb.ansi16(rgbArray);
      this.ansi256Value = convert.rgb.ansi256(rgbArray);
      this.hexValue = '#'+convert.rgb.hex(rgbArray);
      this.hcgValue = convert.rgb.hcg(rgbArray);
      this.appleValue = convert.rgb.apple(rgbArray);
      var index = this.colorsRgb.lastIndexOf(',');
      this.rgbValue = this.colorsRgb.substring(0,index);
    },
    changeShowSketch() {
      this.showSketch = true
    },
    changSketchButton(item) {
      this.showSketch = false;
      console.log(item)
      if (item.isOk) {
        const rgb = item.activeColor;
        this.sketchBgColor = rgb;
        this.colorsRgb = rgb.replace('rgba(','').replace(')','');
        this.changeRgbOrOther();
      } else {
        console.log('取消')
      }
    },
    copyEncry() {
      if (this.generateResult === '') {
        this.$message.error('请先生成UUID后再复制');
        return;
      }
      copyText(this.generateResult, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    del() {
      this.colors = "#000000";
      this.colorsRgb = "0,0,0,1";
      this.sketchBgColor = "#000000";
      this.changeRgbOrOther();
    }
  }
}
</script>

<style scoped lang="scss">
.aes_textarea {
  :deep(.el-textarea__inner) {
    height: calc(100vh - 205px);
  }

  margin: 10px 20px 0 20px;

  .aes-tabs-pane-span {
    font-size: 13px;
  }

  .aes-tabs-pane-input {
    margin: 5px 0 10px 0;
  }

  .bloder {
    font-weight: bolder;
    font-size: 16px;
  }

  .encry-decry-result {
    margin-top: 20px;
  }

  .aes-type {
    padding-right: 10px;
  }
}

.main-title {
  /*padding: 5px 0 0 10px;*/
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.color_selector_border{
  display: flex;
  align-items: center;
  justify-content: center;
}
.color_selector{
  margin-left:10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 5px;
  border: 4px solid white;
  outline:#C8CBD2 solid 1px;
}
.top_area{
  display: flex;
  justify-content: center;
  margin: 25px;
}
.value_area{
  display: flex;
  justify-content:space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
</style>