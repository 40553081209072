<template>
  <div>
    <TopMainTitle/>
    <el-row>
      <el-col :span="12">
        <div style="padding: 10px">
          <el-input class="md5_textarea" type="textarea" resize="none" placeholder="请输入内容" v-model="json"></el-input>
        </div>
      </el-col>

      <el-col :span="12">
        <div style="padding: 10px 10px 10px 0;">
          <el-input class="md5_textarea" type="textarea" resize="none" v-model="compressEscapeJson"></el-input>
        </div>

      </el-col>

    </el-row>
    <div style="text-align: center;margin-top: 10px;">
      <el-button style="width: 20%" type="primary" @click="compressJson">压缩</el-button>
      <el-button style="width: 20%" type="success" @click="escape">转义</el-button>
      <el-button style="width: 20%" type="warning" @click="removeEscape">去除转义</el-button>
      <el-button style="width: 20%;color: white" color="#09BF8C" @click="copyCompressEscapeJson">复制结果</el-button>
    </div>
  </div>
</template>

<script>

import {copyText} from "vue3-clipboard";
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "jsonCompressEscape",
  components: {TopMainTitle},
  data() {
    return {
      itemName:"",
      json: "",
      compressEscapeJson: "",
      formattedJson: {},
      isDisable: true
    };
  },
  methods: {
    compressJson(){
      if(this.json === ''){
        this.$message.error('请输入需要压缩的内容！');
        return;
      }
      this.isDisable = true;
      try {
        this.compressEscapeJson = JSON.stringify(JSON.parse(this.json))
      } catch (e) {
        this.$message.error('格式错误')
      }
    },
    escape(){
      if(this.json === ''){
        this.$message.error('请输入需要转义的内容！');
        return;
      }
      this.isDisable = true;
      try {
        this.compressEscapeJson = this.json.replace(/\\/g,"\\\\").replace(/\"/g,"\\\"");
      } catch (e) {
        this.$message.error('转义失败，请检查格式是否有误！')
      }
    },
    removeEscape(){
      if(this.json === ''){
        this.$message.error('请输入需要去除转义的内容！');
        return;
      }
      this.isDisable = true;
      try {
        this.compressEscapeJson = this.json.replace(/\\\\/g,"\\").replace(/\\\"/g,'\"');
      } catch (e) {
        this.$message.error('去除转义失败，请检查格式是否有误！')
      }
    },
    copyCompressEscapeJson() {
      if(this.compressEscapeJson === ''){
        this.$message.error('内容为空，复制失败！');
        return;
      }
      copyText(this.compressEscapeJson, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.md5_textarea{
  :deep(.el-textarea__inner){
    height: calc(100vh - 205px);
    color: #a11;
    font-family: inherit;
  }
}
.main-title{
  /*padding: 5px 0 0 10px;*/
  padding-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
</style>