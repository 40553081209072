<template>
<div>
  <TopMainTitle/>
  <el-row>
    <el-col :span="12">
      <div style="padding: 10px;">
        <el-input class="json_textarea json" resize="none" type="textarea" placeholder="请输入内容" v-model="json"></el-input>
      </div>
    </el-col>

    <el-col :span="12">
      <div style="padding: 10px 10px 10px 0;height: 100px" class="json_textarea">
        <json-viewer class="json-viewer jv-key" :value="formattedJson" :expand-depth="50" :copyable="copyable"></json-viewer>
      </div>
    </el-col>

  </el-row>
  <div style="text-align: center;margin-top: 10px;">
    <el-button style="width: 20%" type="primary" @click="formatJson">格式化</el-button>
  </div>
</div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "jsonFormatter",
  components: {
    JsonViewer,TopMainTitle
  },
  data() {
    return {
      itemName:"",
      json: "",
      formattedJson: {},
      copyable: {
        copyText:'复制',
        copiedText:'已复制'
      }
    };
  },
  methods: {
    formatJson() {
      try {
        this.formattedJson = JSON.parse(this.json);
      } catch (e) {
        console.log("e---->",e)
        this.$message.error('格式错误')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.json_textarea{
  :deep(.el-textarea__inner){
    height: calc(100vh - 205px);
  }
  .json-viewer {
    border: solid 1px #DDDFE6;
    border-radius: 4px;
    height: calc(100vh - 205px); /* 例如：设置为300像素 */
    overflow-y: auto; /* 添加滚动条以处理超出框高度的内容 */
    :deep(.jv-key) {
      color: #8B2690; /* 设置键的颜色为紫色 */
      font-weight: bolder;
    }
    :deep(.jv-item) {
      font-weight: bolder;
    }
  }

}
.json{
  color:#A1A99D;
}
.main-title{
  /*padding: 5px 0 0 10px;*/
  padding-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
</style>