<template>
  <div>
    <div class="div_select">
      <el-tooltip effect="dark" placement="bottom">
        <svg style="vertical-align: middle ;margin-right: 5px;cursor: pointer;margin-top: 5px" t="1687222561366" class="icon"
             viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2236"
             data-spm-anchor-id="a313x.7781069.0.i0" width="15" height="15">
          <path
              d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
              p-id="2237" fill="#98a1a8"></path>
          <path
              d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7c0-19.7 12.4-37.7 30.9-44.8 59-22.7 97.1-74.7 97.1-132.5 0.1-39.3-17.1-76-48.3-103.3z"
              p-id="2238" fill="#98a1a8"></path>
          <path d="M512 732m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" p-id="2239" fill="#98a1a8"></path>
        </svg>
        <template #content>
          <div style="width: 200px">
            • 搜索后如需再次展示全部工具列表，请点击重置
            <br/>
            • 如找不到需要的工具，请点击右上角“留言反馈”，我们会非常重视您的提议
            <br/>
            • 更多工具在持续开发中，感谢大家支持
          </div>
        </template>
      </el-tooltip>
      <el-input
          class="el_select_input"
          v-model="selectInput"
          style="width: 30%"
          placeholder="输入关键词搜索工具"
          clearable
      />
      <el-button type="primary" @click="serachInput">搜索</el-button>
      <el-button type="warning" @click="delValue">重置</el-button>
    </div>
    <div style="border: 0.1px solid #F0EFEF;margin: 0 10px 0 10px"/>
    <div class="middle_bar">
      <div v-if="isNullDataList">
        <div class="no_data_list">
          <el-image src="img/no_content_60.png" class="no_data_list_image"></el-image>
          <span>{{selectErrorMsg}}</span>
        </div>
      </div>
      <div v-for="tool in dataList">
        <div :id="tool.toolId" class="right_bar_title">
          {{tool.toolName}}
        </div>

        <div class="no_content">
          <el-image src="img/no_content_60.png" v-if="(tool.toolId === 'my_collect' || tool.toolId === 'hot_tool') && tool.item.length === 0" class="no_content_image"></el-image>
          <span v-if="(tool.toolId === 'my_collect' || tool.toolId === 'hot_tool') && tool.item.length === 0">"{{tool.toolName}}" 暂无数据</span>
        </div>

        <div class="main_card">
          <el-card :class="tool.toolClass" v-for="item of tool.item" @click="goJson(item.itemUrl,$event,item.itemName,item.itemUrl)">
            <div class="text item">
              {{item.itemName}}
              <el-image src="img/add_collection_18.png" v-if="!item.itemIsCollect" class="add-collection" @click="addCollection(item)"></el-image>
              <el-image src="img/is_collection_18.png" v-if="item.itemIsCollect" class="is-collection" @click="removeCollection(item)"></el-image>
            </div>
            <div class="bottom_tip">
              <div class="like-count">
                <el-image src="img/like.png" v-if="!item.isLike" class="like" @click="addLike(item)"></el-image>
                <el-image src="img/is_like_16.png" v-if="item.isLike" class="like" @click="addLike(item)"></el-image>
                <div class="count">{{item.itemLikeCount}}</div>
              </div>
              <div class="read-count">
                <el-image src="img/eyes_16.png" class="like"></el-image>
                <div class="count">{{item.readCount}}</div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import {StarFilled} from "@element-plus/icons-vue";
import {ElNotification} from "element-plus";
import {addLikeFun, getToolList, queryLikeReadCountAndHotTool} from "@/utils/method";

export default {
  name: "MainContent",
  components: {StarFilled},
  data(){
    return {
      selectInput:'',
      isNullDataList:false,
      selectErrorMsg:'',
      selectDataList:[],
      dataList: [],
    }
  },
  mounted() {
    // this.systemMsg();
    this.setDataList();
    this.queryHotTool();
  },
  methods: {
    systemMsg(){
      ElNotification({
        title: 'Title',
        message: ('i', { style: 'color: teal' }, 'This is a reminder'),
        duration:0,
      })
    },
    delValue() {
      this.selectInput = '';
      this.selectErrorMsg = '';
      this.isNullDataList = false;
      this.setDataList();
      this.queryHotTool();
    },
    setDataList(){
      this.dataList = getToolList();
    },
    serachInput(){
      this.selectDataList = [];
      this.dataList.forEach(data => {
        if(data.toolId !== 'my_collect' && data.toolId !== 'hot_tool'){
          let itemDataList = {};
          let itemList = [];
          data.item.forEach(item=>{
            if(item.itemName.toLowerCase().indexOf(this.selectInput.toLowerCase()) !== -1){
              itemDataList.toolName = data.toolName;
              itemDataList.toolId = data.toolId;
              itemDataList.toolClass = data.toolClass;
              itemList.push(item);
              itemDataList.item = itemList;
            }
          })
          if(Object.keys(itemDataList).length !== 0){
            this.selectDataList.push(itemDataList);
          }
        }
      })
      if(this.selectDataList.length === 0) {
        this.isNullDataList = true;
        this.selectErrorMsg = '没有找到“'+this.selectInput+'”的相关工具！请调整关键词后重试。';
      }
      this.dataList = this.selectDataList;
    },
    addLike(item){
      if(!item.isLike){
        addLikeFun(item.itemUrl).then(res=>{
          if(res.data.code === 200){
            item.itemLikeCount++;
            item.isLike = true;
          }
        });
      }
    },
    addCollection(item){
      item.itemIsCollect = true;
      localStorage.setItem("my_collect_"+item.itemUrl,JSON.stringify(item));
      this.getList();
    },
    removeCollection(item){
      item.itemIsCollect = false;
      localStorage.removeItem("my_collect_"+item.itemUrl);
      this.getList();
      this.removeCollectionByFun(item.itemUrl);
    },
    goJson(url,event,itemName,itemUrl) {
      //当我点击整个el-card中除like-count以外的地方时，才跳转到对应的页面
      let target = event.target;
      while (target) {
        if (target.classList.contains('like-count') || target.classList.contains('add-collection') || target.classList.contains('is-collection')) {
          return;
        }
        target = target.parentElement;
      }
      this.$router.push({name: url});
    },
    getList(){
      this.dataList.forEach(data => {
        if(data.toolId === 'my_collect'){
          data.item = [];
          for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            if (key.indexOf('my_collect') !== -1) {
              data.item.push(JSON.parse(localStorage.getItem(key)));
            }
          }
        }else{
          for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            if (key.indexOf('my_collect') !== -1) {
              data.item.forEach(dataItem=>{
                if("my_collect_"+dataItem.itemUrl === key){
                  dataItem.itemIsCollect = true;
                }
              })
            }
          }
        }
      })
    },
    removeCollectionByFun(itemUrl){
      this.dataList.forEach(data => {
        data.item.forEach(dataItem=>{
          if(dataItem.itemUrl === itemUrl){
            dataItem.itemIsCollect = false;
          }
        })
      })
    },
    queryHotTool(){
      queryLikeReadCountAndHotTool().then(res=>{
        if(res.data.code === 200){
          let queryHotToolList = res.data.data.hotTool;
          this.dataList.forEach(data => {
            if(data.toolId === 'hot_tool'){
              data.item = queryHotToolList;

              let queryLikeCountList = res.data.data.likeAndReadCount;
              this.dataList.forEach(data => {
                if(data.toolId === 'my_collect'){
                  data.item = [];
                  for (let i = 0; i < localStorage.length; i++) {
                    let key = localStorage.key(i);
                    if (key.indexOf('my_collect') !== -1) {
                      data.item.push(JSON.parse(localStorage.getItem(key)));
                    }
                  }
                }else{
                  for (let i = 0; i < localStorage.length; i++) {
                    let key = localStorage.key(i);
                    if (key.indexOf('my_collect') !== -1) {
                      data.item.forEach(dataItem=>{
                        if("my_collect_"+dataItem.itemUrl === key){
                          dataItem.itemIsCollect = true;
                        }
                      })
                    }
                  }
                }
              })

              if(queryLikeCountList.length > 0){
                for (let i = 0; i < queryLikeCountList.length; i++) {
                  let imageUrl = '';
                  let isLikeCount = 0;
                  let isReadCount = 0;
                  Object.keys(queryLikeCountList[i]).forEach(key=>{
                    if(key.indexOf("online_tool_like_count_") !== -1){
                      isLikeCount = queryLikeCountList[i][key];
                    }else{
                      isReadCount = queryLikeCountList[i][key];
                    }
                    imageUrl = key.replace('online_tool_like_count_','').replace('online_tool_read_count_','');
                  })
                  this.dataList.forEach(data => {
                    /*if(data.toolId !== 'my_collect'){
                      data.item.forEach(dataItem=>{
                        if(dataItem.itemUrl === imageUrl){
                          dataItem.itemLikeCount = isLikeCount;
                        }
                      })
                    }*/
                    data.item.forEach(dataItem=>{
                      if(dataItem.itemUrl === imageUrl){
                        if(isLikeCount !== 0){
                          dataItem.itemLikeCount = isLikeCount;
                        }
                        if(isReadCount !== 0){
                          dataItem.readCount = isReadCount;
                        }
                      }
                    })
                  })
                }
              }
            }
          })
        }
      });
    }
  }
}
</script>


<style scoped lang="scss">

.main_card{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding:10px 20px;
  :deep(.el-card){
    --el-card-padding: 15px
  }
}
.text {
  display: flex;
  justify-content: space-between;
  /*color: #4caf50;*/
  color: #000;
  font-size: .95rem;
  line-height: 1.25rem;
}


.box-card {
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
}

.right_bar_title{
  font-size: 15px;
  padding: 20px 0 5px 20px;
}
.card-hot-tool{
  background-color: #F8E3E3;
}
.card-my-collect{
  background-color: #D2FCCD;
}
.card-image-tool{
  background-color: #E1EAC2;
}

.card-encry-decry{
  background-color: #F3EEC6;
}

.card-code-decode{
  background-color: #C6E4F3;
}

.card-color-handle{
  background-color: #E3D8F7;
}

.card-text-tool{
  background-color: #B7D29B;
}

.card-develop-handle{
  background-color: #B6EDD8;
}

.card-color-tool{
  background-color: #88E0DB;
}

.card-calculate-tool{
  background-color: #CAE69D;
}

.bottom_tip{
  margin-bottom: 10px;
  .read-count{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    float: left;
    color: #999;
    .like{
      font-size: 13px;
      margin-right: 4px;
      display: flex;
      align-items: center;
    }
    .count{
      font-size: 13px;
    }
  }
  .like-count{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    float: right;
    color: #999;
    .like{
      font-size: 13px;
      margin-right: 4px;
      display: flex;
      align-items: center;
    }
    .count{
      font-size: 13px;
    }
  }
}

.add-collection{
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  padding-bottom: 20px;
  width: 6.5%;
  height: 6.5%;
  &:hover{
    color: #f1bc32;
  }
}
.is-collection{
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 20px;
  width: 6%;
  height: 6%;
}
.no_content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #8c939d;
  //优化滚动条
  //margin-left: -8px;
  //margin-right: -8px;
  font-size: 12px;
  .no_content_image{
    width: 10%;
    height: 10%;
    margin: auto;
  }
}
.div_select{
  text-align: center;
  display: flex;
  justify-content: flex-end;
  margin: 10px 20px 10px 0;
  .el_select_input{
    margin-right: 10px;
  }
}

.no_data_list{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #8c939d;
  .no_data_list_image{
    width: 15%;
    height: 15%;
    margin: auto;
  }
}
.middle_bar{
  background-color: #fff;
  height: calc(100vh - 153px);
  overflow: auto;
  border-radius: 8px;
}
</style>