<template>
  <div>
    <TopMainTitle/>
    <div class="aes_textarea">
      <div>
        <span class="aes-tabs-pane-span">生成选项</span>
        <div style="display: flex;justify-content:space-between;padding-top: 5px;padding-bottom: 5px">
          <el-switch
              v-model="number"
              class="mb-2"
              active-text="含数字（0~9）"
          />
          <el-switch
              v-model="lowercase"
              class="mb-2"
              active-text="含小写字母（a~z）"
          />
          <el-switch
              v-model="uppercase"
              class="mb-2"
              active-text="含大写字母（A~Z）"
          />
          <el-switch
              v-model="specialSymbols"
              class="mb-2"
              active-text="含特殊符号（~!@#$..）"
          />

        </div>

        <span class="aes-tabs-pane-span aes-type">密码长度：</span>
        <el-input
            v-model="passwordLength"
            type="input"
            class="aes-tabs-pane-input"
            resize="none"
            maxlength="3"
            placeholder="请输入密码长度"/>

        <span class="aes-tabs-pane-span aes-type">增加前缀：</span>
        <el-input
            v-model="passwordPrefix"
            type="input"
            class="aes-tabs-pane-input"
            resize="none"
            maxlength="10"
            placeholder="请输入需要增加的前缀（选填）"/>

        <span class="aes-tabs-pane-span aes-type">增加后缀：</span>
        <el-input
            v-model="passwordSuffix"
            type="input"
            class="aes-tabs-pane-input"
            resize="none"
            maxlength="10"
            placeholder="请输入需要增加的后缀（选填）"/>
      </div>

      <div style="text-align: center;margin-top: 20px">
        <el-button style="width: 20%" type="primary" @click="generateFun">生成</el-button>
        <el-button style="width: 20%;color: white" color="#09BF8C" @click="copyEncry">复制结果</el-button>
        <el-button style="width: 20%" type="warning" @click="del">清空</el-button>
      </div>
      <div class="encry-decry-result">
        <span class="aes-tabs-pane-span bloder">生成结果</span>
        <el-input
            v-model="generateResult"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4}"
            class="aes-tabs-pane-input"
            resize="none"
            placeholder="生成结果"/>
      </div>

    </div>
  </div>
</template>

<script>
import {copyText} from 'vue3-clipboard'
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "randomPasswordGeneration",
  components: {TopMainTitle},
  data() {
    return {
      itemName:"",
      number:true,
      lowercase:true,
      uppercase:true,
      specialSymbols:false,
      passwordLength:10,
      passwordPrefix:'',
      passwordSuffix:'',
      generateResult:'',
    };
  },
  methods: {
    generateFun(){
      if(!this.number && !this.lowercase && !this.uppercase && !this.specialSymbols){
        this.$message.error('请至少选择一个生成选项');
        return;
      }
      if(this.passwordLength <= 1){
        this.$message.error('密码长度须大于1位');
        return;
      }
      let chart = '';
      if(this.number){
        chart = chart+'0123456789';
      }
      if(this.lowercase){
        chart = chart+'abcdefghijklmnopqrstuvwxyz';
      }
      if(this.uppercase){
        chart = chart+'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      }
      if(this.specialSymbols){
        chart = chart+'`~!@#$%^&*()-_=+[{]}\\|;:\'\\",<.>/?';
      }
      let password = "";
      for (var j = 0, k = this.passwordLength; j < k; j++) {
        if (chart.length < 1) {
          break;
        }
        var index = this.rand(chart.length);
        password += chart[index];
      }
      this.generateResult = this.passwordPrefix + password + this.passwordSuffix;
    },
    rand(max) {
      return Math.floor(Math.random() * max);
    },
    copyEncry() {
      if (this.generateResult === '') {
        this.$message.error('请先生成密码后再复制');
        return;
      }
      copyText(this.generateResult, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    del() {
      this.specialSymbols = false;
      this.passwordLength = 10;
      this.passwordPrefix = '';
      this.passwordSuffix = '';
      this.generateResult = '';
    }
  }
}
</script>

<style scoped lang="scss">
.aes_textarea {
  :deep(.el-textarea__inner) {
    height: calc(100vh - 205px);
  }

  margin: 10px 20px 0 20px;

  .aes-tabs-pane-span {
    font-size: 13px;
  }

  .aes-tabs-pane-input {
    margin: 5px 0 10px 0;
  }

  .bloder {
    font-weight: bolder;
    font-size: 16px;
  }

  .encry-decry-result {
    margin-top: 20px;
  }

  .aes-type {
    padding-right: 10px;
  }
}

.main-title {
  /*padding: 5px 0 0 10px;*/
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

//.aes_textarea{
//  :deep(.el-tabs__item.is-top){
//    width: 200px;
//    font-size: 20px;
//    color: red;
//  }
//  :deep(.el-tabs__active-bar.is-top){
//    background-color: green;
//  }
//}

</style>