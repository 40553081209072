<template>
  <div>
    <TopMainTitle/>
    <el-row>
      <el-col :span="12">
        <div style="padding: 10px">
          <el-input class="md5_textarea" type="textarea" resize="none" placeholder="请输入需要大小写转换的字母"
                    v-model="beforeValue"></el-input>
        </div>
      </el-col>

      <el-col :span="12">
        <div style="padding: 10px 10px 10px 0;">
          <el-input class="md5_textarea" type="textarea" resize="none" placeholder="转换结果"
                    v-model="afterValue"></el-input>
        </div>

      </el-col>

    </el-row>
    <div style="text-align: center;margin-top: 10px;">
      <el-button style="width: 20%" type="primary" @click="toUpperCase">转大写</el-button>
      <el-button style="width: 20%" type="primary" @click="toLowerCase">转小写</el-button>
      <el-button style="width: 20%;color: white" color="#09BF8C" @click="copyConversion">复制结果</el-button>
      <el-button style="width: 20%" type="warning" @click="del">清空</el-button>
    </div>
  </div>
</template>

<script>

import {copyText} from "vue3-clipboard";
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "letterUpperLower",
  components: {TopMainTitle},
  data() {
    return {
      beforeValue: '',
      afterValue: '',
    };
  },
  methods: {
    toUpperCase() {
      this.afterValue = this.beforeValue.toUpperCase();
    },
    toLowerCase() {
      this.afterValue = this.beforeValue.toLowerCase();
    },
    copyConversion() {
      if (this.afterValue === '') {
        this.$message.error('内容为空，复制失败！');
        return;
      }
      copyText(this.afterValue, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    del() {
      this.beforeValue = '';
      this.afterValue = '';
    }
  }
}
</script>

<style scoped lang="scss">
.md5_textarea {
  :deep(.el-textarea__inner) {
    height: calc(100vh - 205px);
    color: #6A6A6A;
    font-family: inherit;
  }
}

.main-title {
  /*padding: 5px 0 0 10px;*/
  padding-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
</style>