<template>
  <div>
    <TopMainTitle/>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="RSA公私钥生成" name="first">
        <el-row>
          <el-col :span="12" style="padding: 0 10px 10px 0">
            <span class="el_row_span">RSA公钥</span>
            <div style="padding-top: 5px">
              <el-input class="md5_textarea" type="textarea" resize="none" v-model="publicKey"></el-input>
            </div>
          </el-col>

          <el-col :span="12" style="padding: 0 10px 10px 10px">
            <span class="el_row_span">RSA私钥</span>
            <div style="padding-top: 5px">
              <el-input class="md5_textarea" type="textarea" resize="none" v-model="privateKey"></el-input>
            </div>

          </el-col>

        </el-row>
        <div style="text-align: center;margin-top: 10px;">
          <el-tooltip effect="dark" placement="bottom">
            <svg style="vertical-align: middle ;margin-right: 5px;cursor: pointer" t="1687222561366" class="icon"
                 viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2236"
                 data-spm-anchor-id="a313x.7781069.0.i0" width="15" height="15">
              <path
                  d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                  p-id="2237" fill="#98a1a8"></path>
              <path
                  d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7c0-19.7 12.4-37.7 30.9-44.8 59-22.7 97.1-74.7 97.1-132.5 0.1-39.3-17.1-76-48.3-103.3z"
                  p-id="2238" fill="#98a1a8"></path>
              <path d="M512 732m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" p-id="2239" fill="#98a1a8"></path>
            </svg>
            <template #content>
              <div style="width: 200px">
                • 选择4096 bit长度时，会导致页面短暂卡顿，请耐心等待生成结果。
              </div>
            </template>
          </el-tooltip>
          <span>密钥长度:</span>
          <el-select v-model="keyLength" placeholder="Select" style="width: 20%;padding: 0 10px 0 10px">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
          <el-button style="width: 15%" type="primary" @click="generatePublicAndPrivateKey">生成公私密钥</el-button>
          <el-button style="width: 10%;color: white" color="#09BF8C" @click="copyPublicKeyValue">复制公钥</el-button>
          <el-button style="width: 10%;color: white" color="#09BF8C" @click="copyPrivateKeyValue">复制私钥</el-button>
          <el-button style="width: 10%" type="warning" @click="delValue">清空</el-button>
        </div>
      </el-tab-pane>



      <el-tab-pane label="根据公钥加密" name="second">
        <el-row>
          <el-col :span="12" style="padding: 0 10px 10px 0">
            <span class="el_row_span_el_col">请输入公钥</span>
            <div style="padding-top: 5px;padding-bottom: 5px">
              <el-input
                  v-model="inputPublicKey"
                  type="textarea"
                  class="aes-tabs-pane-input"
                  resize="none"
                  placeholder="请输入公钥"/>
            </div>

            <span class="el_row_span_el_col">请输入需要加密的字符串</span>
            <div style="padding-top: 5px">
              <el-input
                  v-model="inputNeedEncodeOrDecode"
                  type="textarea"
                  class="aes-tabs-pane-input"
                  resize="none"
                  placeholder="请输入需要加密的字符串"/>
            </div>
          </el-col>

          <el-col :span="12" style="padding: 0 10px 10px 10px">
            <span class="el_row_span_el_col">加密结果</span>
            <div style="padding-top: 5px">
              <el-input class="md5_textarea" type="textarea" resize="none" v-model="encodeResult"></el-input>
            </div>

          </el-col>
        </el-row>
        <div style="text-align: center;margin-top: 10px;">
          <el-button style="width: 20%" type="success" @click="rsaEncodeByPublicKey">加密</el-button>
          <el-button style="width: 20%;color: white" color="#09BF8C" @click="copyEncodeValue">复制结果</el-button>
          <el-button style="width: 20%" type="warning" @click="delValue">清空</el-button>
        </div>
      </el-tab-pane>



      <el-tab-pane label="根据私钥解密" name="third">
        <el-row>
          <el-col :span="12" style="padding: 0 10px 10px 0">
            <span class="el_row_span_el_col">请输入私钥</span>
            <div style="padding-top: 5px;padding-bottom: 5px">
              <el-input
                  v-model="inputPrivateKey"
                  type="textarea"
                  class="aes-tabs-pane-input"
                  resize="none"
                  placeholder="请输入公钥"/>
            </div>

            <span class="el_row_span_el_col">请输入需要解密的字符串</span>
            <div style="padding-top: 5px">
              <el-input
                  v-model="inputNeedEncodeOrDecode"
                  type="textarea"
                  class="aes-tabs-pane-input"
                  resize="none"
                  placeholder="请输入需要解密的字符串"/>
            </div>
          </el-col>

          <el-col :span="12" style="padding: 0 10px 10px 10px">
            <span class="el_row_span_el_col">解密结果</span>
            <div style="padding-top: 5px">
              <el-input class="md5_textarea" type="textarea" resize="none" v-model="decodeResult"></el-input>
            </div>

          </el-col>
        </el-row>
        <div style="text-align: center;margin-top: 10px;">
          <el-button style="width: 20%" type="primary" @click="rsaDecodeByPrivateKey">解密</el-button>
          <el-button style="width: 20%;color: white" color="#09BF8C" @click="copyDecodeValue">复制结果</el-button>
          <el-button style="width: 20%" type="warning" @click="delValue">清空</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import JSEncrypt from 'jsencrypt';
import {copyText} from "vue3-clipboard";
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "rsaEncryDecry",
  components: {TopMainTitle},
  data() {
    return {
      itemName:"",
      activeName: 'first',
      publicKey:'',
      privateKey:'',
      keyLength:512,
      options:[
        {
          value: 512,
          label: '512 bit',
        },
        {
          value: 1024,
          label: '1024 bit',
        },
        {
          value: 2048,
          label: '2048 bit',
        },
        {
          value: 4096,
          label: '4096 bit',
        },
      ],
      inputPublicKey:'',
      inputPrivateKey:'',
      inputNeedEncodeOrDecode:'',
      encodeResult:'',
      decodeResult:'',
    };
  },
  methods: {
    rsaEncodeByPublicKey(){
      if(this.inputPublicKey === ''){
        this.$message.error('请输入公钥');
        return;
      }
      if(this.inputNeedEncodeOrDecode === ''){
        this.$message.error('请输入需要加密的字符串');
        return;
      }
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(this.inputPublicKey);
      this.encodeResult = encrypt.encrypt(this.inputNeedEncodeOrDecode);
    },
    rsaDecodeByPrivateKey(){
      if(this.inputPrivateKey === ''){
        this.$message.error('请输入私钥');
        return;
      }
      if(this.inputNeedEncodeOrDecode === ''){
        this.$message.error('请输入需要解密的字符串');
        return;
      }
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(this.inputPrivateKey);
      this.decodeResult = encrypt.decrypt(this.inputNeedEncodeOrDecode);
    },
    handleClick(tab, event) {
      this.inputNeedEncodeOrDecode = '';
      this.encodeResult = '';
      this.decodeResult = '';
    },
    generatePublicAndPrivateKey(){
      const key = new JSEncrypt({ default_key_size: this.keyLength});
      key.getKey();
      this.publicKey = key.getPublicKey();
      this.privateKey = key.getPrivateKey();

      this.inputPublicKey = this.publicKey;
      this.inputPrivateKey = this.privateKey;
    },
    copyEncodeValue() {
      if (this.encodeResult === '') {
        this.$message.error('请先加密后再复制');
        return;
      }
      copyText(this.encodeResult, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    copyDecodeValue() {
      if (this.decodeResult === '') {
        this.$message.error('请先解密后再复制');
        return;
      }
      copyText(this.decodeResult, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    copyPublicKeyValue() {
      if (this.publicKey === '') {
        this.$message.error('请先生成公钥后再复制');
        return;
      }
      copyText(this.publicKey, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    copyPrivateKeyValue() {
      if (this.privateKey === '') {
        this.$message.error('请先生成私钥后再复制');
        return;
      }
      copyText(this.privateKey, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    delValue() {
      this.publicKey='';
      this.privateKey='';
      this.inputPublicKey='';
      this.inputPrivateKey='';
      this.inputNeedEncodeOrDecode='';
      this.encodeResult='';
      this.decodeResult='';
    },
  }
};
</script>

<style scoped lang="scss">
.demo-tabs{
  margin: 10px;
  .md5_textarea{
    :deep(.el-textarea__inner){
      height: calc(100vh - 310px);
      //color: #a11;
      font-family: inherit;
    }
  }
  .aes-tabs-pane-input{
    :deep(.el-textarea__inner){
      height: calc((100vh - 341px)/2);
      //color: #a11;
      font-family: inherit;
    }
  }
  .el_row_span{
    font-size: 20px;
    font-weight: bold;
  }
  .el_row_span_el_col{
    font-size: 14px;
    font-weight: bold;
  }
}

.main-title{
  /*padding: 5px 0 0 10px;*/
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

//.aes_textarea{
//  :deep(.el-tabs__item.is-top){
//    width: 200px;
//    font-size: 20px;
//    color: red;
//  }
//  :deep(.el-tabs__active-bar.is-top){
//    background-color: green;
//  }
//}

</style>