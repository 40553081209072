<template>
  <div>
    <TopMainTitle/>
    <div class="aes_textarea">
      <div>
        <span class="aes-tabs-pane-span">编码前的字符串</span>
        <el-input
            v-model="beforeValue"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 6}"
            class="aes-tabs-pane-input"
            resize="none"
            placeholder="请输入需要编码的字符串"/>
      </div>
      <div style="text-align: center;">
        <el-button style="width: 20%" type="primary" @click="encryFun">编码</el-button>
        <el-button style="width: 20%" type="success" @click="decryFun">解码</el-button>
        <el-button style="width: 20%;color: white" color="#09BF8C" @click="copyDecry">复制结果</el-button>
        <el-button style="width: 20%" type="warning" @click="del">清空</el-button>
      </div>
      <div class="encry-decry-result">
        <span class="aes-tabs-pane-span bloder">编码后的字符串</span>
        <el-input
            v-model="afterValue"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 6}"
            class="aes-tabs-pane-input"
            resize="none"
            placeholder="编码后的字符串"/>
      </div>
    </div>
  </div>
</template>

<script>
import {copyText} from "vue3-clipboard";
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "base64CodeDecode",
  components: {TopMainTitle},
  data() {
    return {
      itemName:"",
      beforeValue: "",
      afterValue: "",
      base64EncodeChars: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
      base64DecodeChars:[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63,
        52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1,
        -1,  0,  1,  2,  3,  4,  5,  6,  7,  8,  9, 10, 11, 12, 13, 14,
        15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1,
        -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
        41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1]
    };
  },
  methods: {
    encryFun() {
      if(this.beforeValue === ''){
        this.$message.error('请输入需要编码的字符串');
        return;
      }
      try {
        this.afterValue = this.base64encode(this.utf16to8(this.beforeValue));
      } catch (e) {
        this.$message.error('格式错误');
      }
    },
    decryFun() {
      if(this.beforeValue === ''){
        this.$message.error('请输入需要解码的字符串');
        return;
      }
      try {
        this.afterValue = this.utf8to16(this.base64decode(this.beforeValue));
      } catch (e) {
        this.$message.error('格式错误');
      }
    },
    copyDecry() {
      if(this.afterValue === ''){
        this.$message.error('请先编码数据后再复制');
        return;
      }
      copyText(this.afterValue, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    del(){
      this.beforeValue = '';
      this.afterValue = '';
    },
    utf16to8(str) {
      var out, i, len, c;
      out = "";
      len = str.length;
      for(i = 0; i < len; i++) {
        c = str.charCodeAt(i);
        if ((c >= 0x0001) && (c <= 0x007F)) {
          out += str.charAt(i);
        } else if (c > 0x07FF) {
          out += String.fromCharCode(0xE0 | ((c >> 12) & 0x0F));
          out += String.fromCharCode(0x80 | ((c >>  6) & 0x3F));
          out += String.fromCharCode(0x80 | ((c >>  0) & 0x3F));
        } else {
          out += String.fromCharCode(0xC0 | ((c >>  6) & 0x1F));
          out += String.fromCharCode(0x80 | ((c >>  0) & 0x3F));
        }
      }
      return out;
    },
    base64encode(str) {
      var out, i, len;
      var c1, c2, c3;

      len = str.length;
      i = 0;
      out = "";
      while(i < len) {
        c1 = str.charCodeAt(i++) & 0xff;
        if(i == len)
        {
          out += this.base64EncodeChars.charAt(c1 >> 2);
          out += this.base64EncodeChars.charAt((c1 & 0x3) << 4);
          out += "==";
          break;
        }
        c2 = str.charCodeAt(i++);
        if(i == len)
        {
          out += this.base64EncodeChars.charAt(c1 >> 2);
          out += this.base64EncodeChars.charAt(((c1 & 0x3)<< 4) | ((c2 & 0xF0) >> 4));
          out += this.base64EncodeChars.charAt((c2 & 0xF) << 2);
          out += "=";
          break;
        }
        c3 = str.charCodeAt(i++);
        out += this.base64EncodeChars.charAt(c1 >> 2);
        out += this.base64EncodeChars.charAt(((c1 & 0x3)<< 4) | ((c2 & 0xF0) >> 4));
        out += this.base64EncodeChars.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >>6));
        out += this.base64EncodeChars.charAt(c3 & 0x3F);
      }
      return out;
    },
    utf8to16(str) {
      var out, i, len, c;
      var char2, char3;
      out = "";
      len = str.length;
      i = 0;
      while(i < len) {
        c = str.charCodeAt(i++);
        switch(c >> 4) {
          case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
          // 0xxxxxxx
          out += str.charAt(i-1);
          break;
          case 12: case 13:
          // 110x xxxx   10xx xxxx
          char2 = str.charCodeAt(i++);
          out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
          break;
          case 14:
            // 1110 xxxx  10xx xxxx  10xx xxxx
            char2 = str.charCodeAt(i++);
            char3 = str.charCodeAt(i++);
            out += String.fromCharCode(((c & 0x0F) << 12) |
                ((char2 & 0x3F) << 6) |
                ((char3 & 0x3F) << 0));
            break;
        }
      }
      return out;
    },
    base64decode(str) {
      var c1, c2, c3, c4;
      var i, len, out;

      len = str.length;
      i = 0;
      out = "";
      while(i < len) {
        /* c1 */
        do {
          c1 = this.base64DecodeChars[str.charCodeAt(i++) & 0xff];
        } while(i < len && c1 == -1);
        if(c1 == -1)
          break;

        /* c2 */
        do {
          c2 = this.base64DecodeChars[str.charCodeAt(i++) & 0xff];
        } while(i < len && c2 == -1);
        if(c2 == -1)
          break;

        out += String.fromCharCode((c1 << 2) | ((c2 & 0x30) >> 4));

        /* c3 */
        do {
          c3 = str.charCodeAt(i++) & 0xff;
          if(c3 == 61)
            return out;
          c3 = this.base64DecodeChars[c3];
        } while(i < len && c3 == -1);
        if(c3 == -1)
          break;

        out += String.fromCharCode(((c2 & 0XF) << 4) | ((c3 & 0x3C) >> 2));

        /* c4 */
        do {
          c4 = str.charCodeAt(i++) & 0xff;
          if(c4 == 61)
            return out;
          c4 = this.base64DecodeChars[c4];
        } while(i < len && c4 == -1);
        if(c4 == -1)
          break;
        out += String.fromCharCode(((c3 & 0x03) << 6) | c4);
      }
      return out;
    }
  }
}
</script>

<style scoped lang="scss">
.aes_textarea{
  :deep(.el-textarea__inner){
    height: calc(100vh - 205px);
  }
  margin: 0 20px 0 20px;
  .aes-tabs-pane-span{
    font-size: 13px;
  }
  .aes-tabs-pane-input{
    margin: 5px 0 25px 0;
  }
  .bloder{
    font-weight: bolder;
    font-size: 16px;
  }
  .encry-decry-result{
    margin-top: 25px;
  }
  .aes-type{
    padding-right: 10px;
  }
}
.main-title{
  /*padding: 5px 0 0 10px;*/
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

//.aes_textarea{
//  :deep(.el-tabs__item.is-top){
//    width: 200px;
//    font-size: 20px;
//    color: red;
//  }
//  :deep(.el-tabs__active-bar.is-top){
//    background-color: green;
//  }
//}

</style>