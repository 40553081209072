<template>
  <div class="main_body">
    <div class="left_bar">
      <a v-for="(item, index) in dataList" :key="index" :class="(activeIndex === index) ? 'custom-link_on' : 'custom-link'"
         @click="fanhui(index)" :href="item.url">
        {{ item.name }}
      </a>
    </div>


    <div ref="scrollRight" class="middle_bar" @scroll="handleScroll">
      <router-view/>
    </div>

<!--    <div class="right_bar">-->
<!--      <a v-for="(item, index) in dataList" :key="index" :class="(activeIndex === index) ? 'custom-link_on' : 'custom-link'" @click="fanhui(index)" :href="item.url">-->
<!--        {{ item.name }}-->
<!--      </a>-->
<!--    </div>-->
  </div>
</template>

<script>
import request from "@/utils/request";
import axios from "axios";
import {queryToolDataList, updateMsgAlert} from "@/utils/method";

export default {
  name: "Main",
  data(){
    return {
      dataList: [],
      activeIndex: 0
    }
  },
  methods: {
    fanhui(index) {
      this.activeIndex = index;
      //返回主页
      this.$router.push({name: 'MainContent'});
    },
    handleScroll(event) {
      const scrollTop = event.target.scrollTop;
      const rightBar = this.$refs.scrollRight;
      const sections = rightBar.querySelectorAll('.right_bar_title');
      // console.log(scrollTop, sections);
      // 找到当前滚动位置对应的标题索引
      let index = 0;
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        if (section.offsetTop <= scrollTop+10) {
          index = i;
        }
      }
      // 更新左侧导航的激活状态
      this.activeIndex = index;
    }
  },
  mounted() {
    this.dataList = queryToolDataList();
    updateMsgAlert();
    // request.post("/current/insertFeedBack",{
    //   startTime:"111",
    //   endTime:"222",
    //   feedbackValue:"333",
    //   contactInformation:"444",
    //   systemType:"555"}).then(res => {
    //   console.log(res);
    // });
    // axios.post("http://localhost:8086/current/insertFeedBack",{
    //   startTime:"111",
    //   endTime:"222",
    //   feedbackValue:"333",
    //   contactInformation:"444",
    //   systemType:"555"}).then(res => {
    //   console.log(res.data);
    // });
  },
}
</script>

<style scoped lang="scss">
.main_body {
  background-color: rgb(247, 249, 252);
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 50px;
  position: relative;
}

.left_bar{
  background-color: #fff;
  height: calc(100vh - 100px);
  width: 200px;
  margin-right: 20px;
  box-shadow: 0px 0px 2px 0px rgba(145,158,171,0.2), 0px 12px 24px -4px rgba(145,158,171,0.12);
  border-radius: 8px;
}

.middle_bar{
  width: 970px;
  background-color: #fff;
  height: calc(100vh - 100px);
  box-shadow: 0px 0px 2px 0px rgba(145,158,171,0.2), 0px 12px 24px -4px rgba(145,158,171,0.12);
  border-radius: 8px;
}

.custom-link{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 30px 20px 20px 20px;
  font-size: 14px;
  color: #000;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  cursor: pointer;
  &:hover{
    background-color: #f0f0f0;
  }
}
.custom-link_on{
  color: #27366F;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 30px 20px 20px 20px;
  font-size: 14px;
  font-weight: bolder;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}
//.right_bar{
//  background-color: #F3F4F4;
//  height: calc(100vh - 100px);
//  width: 250px;
//  margin-left: 20px;
//  box-shadow: 0px 0px 2px 0px rgba(145,158,171,0.2), 0px 12px 24px -4px rgba(145,158,171,0.12);
//  border-radius: 8px;
//}
//.div_select{
//  display: flex;
//  justify-content: flex-end;
//  margin: 0 20px 10px 0;
//  .el_select_input{
//    display: flex;
//    justify-content: center;
//    margin-right: 10px;
//  }
//}
</style>