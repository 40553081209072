<template>
  <div>
    <TopMainTitle/>
    <div class="aes_textarea">
      <div>
        <span class="aes-tabs-pane-span">生成选项</span>
        <div style="display: flex;justify-content:space-between;padding-top: 5px;padding-bottom: 5px">
          <el-switch
              v-model="strUpperOrLower"
              class="mb-2"
              active-text="字母大写"
          />
          <el-switch
              v-model="delimiter"
              class="mb-2"
              active-text="分隔符(-)"
          />
          <el-switch
              v-model="needSymbol"
              class="mb-2"
              active-text="需要{}"
          />
          <el-switch
              v-model="toBase64"
              class="mb-2"
              active-text="转Base64"
          />
        </div>

        <span class="aes-tabs-pane-span aes-type">生成数量：</span>
        <el-input
            v-model="generationLength"
            type="input"
            class="aes-tabs-pane-input"
            resize="none"
            maxlength="3"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            placeholder="请输入生成数量"/>
      </div>

      <div style="text-align: center;margin-top: 20px">
        <el-button style="width: 20%" type="primary" @click="generateFun">生成</el-button>
        <el-button style="width: 20%;color: white" color="#09BF8C" @click="copyEncry">复制结果</el-button>
        <el-button style="width: 20%" type="warning" @click="del">清空</el-button>
      </div>
      <div class="encry-decry-result">
        <span class="aes-tabs-pane-span bloder">生成结果</span>
        <el-input
            v-model="generateResult"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 6}"
            class="aes-tabs-pane-input"
            resize="none"
            placeholder="生成结果"/>
      </div>

    </div>
  </div>
</template>

<script>
import {copyText} from 'vue3-clipboard'
import { v4 as uuidv4 } from 'uuid';
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "uuidGeneration",
  components: {TopMainTitle},
  data() {
    return {
      itemName:"",
      strUpperOrLower:false,
      delimiter:true,
      needSymbol:false,
      toBase64:false,
      generationLength:1,
      generateResult:'',
      base64EncodeChars: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
      base64DecodeChars:[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63,
        52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1,
        -1,  0,  1,  2,  3,  4,  5,  6,  7,  8,  9, 10, 11, 12, 13, 14,
        15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1,
        -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
        41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1]
    };
  },
  methods: {
    generateFun(){
      if(this.generationLength < 1){
        this.$message.error('生成数量须大于0');
        return;
      }
      let generateResultStr = [];
      for (let i = 0; i < this.generationLength; i++) {
        generateResultStr.push(uuidv4());
      }
      for (let i = 0; i < generateResultStr.length; i++) {
        if(this.needSymbol){
          generateResultStr[i] = '{'+generateResultStr[i]+'}'
        }
        if(this.strUpperOrLower){
          generateResultStr[i] = generateResultStr[i].toUpperCase();
        }
        if(!this.delimiter){
          generateResultStr[i] = generateResultStr[i].replaceAll('-','');
        }
        if(this.toBase64){
          try {
            generateResultStr[i] = this.base64encode(this.utf16to8(generateResultStr[i]));
          } catch (e) {
            this.$message.error('格式错误');
          }
        }
        generateResultStr[i] = generateResultStr[i]+'\n';
      }
      this.generateResult = generateResultStr.toString().replaceAll(',','');
    },
    utf16to8(str) {
      var out, i, len, c;
      out = "";
      len = str.length;
      for(i = 0; i < len; i++) {
        c = str.charCodeAt(i);
        if ((c >= 0x0001) && (c <= 0x007F)) {
          out += str.charAt(i);
        } else if (c > 0x07FF) {
          out += String.fromCharCode(0xE0 | ((c >> 12) & 0x0F));
          out += String.fromCharCode(0x80 | ((c >>  6) & 0x3F));
          out += String.fromCharCode(0x80 | ((c >>  0) & 0x3F));
        } else {
          out += String.fromCharCode(0xC0 | ((c >>  6) & 0x1F));
          out += String.fromCharCode(0x80 | ((c >>  0) & 0x3F));
        }
      }
      return out;
    },
    base64encode(str) {
      var out, i, len;
      var c1, c2, c3;

      len = str.length;
      i = 0;
      out = "";
      while(i < len) {
        c1 = str.charCodeAt(i++) & 0xff;
        if(i == len)
        {
          out += this.base64EncodeChars.charAt(c1 >> 2);
          out += this.base64EncodeChars.charAt((c1 & 0x3) << 4);
          out += "==";
          break;
        }
        c2 = str.charCodeAt(i++);
        if(i == len)
        {
          out += this.base64EncodeChars.charAt(c1 >> 2);
          out += this.base64EncodeChars.charAt(((c1 & 0x3)<< 4) | ((c2 & 0xF0) >> 4));
          out += this.base64EncodeChars.charAt((c2 & 0xF) << 2);
          out += "=";
          break;
        }
        c3 = str.charCodeAt(i++);
        out += this.base64EncodeChars.charAt(c1 >> 2);
        out += this.base64EncodeChars.charAt(((c1 & 0x3)<< 4) | ((c2 & 0xF0) >> 4));
        out += this.base64EncodeChars.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >>6));
        out += this.base64EncodeChars.charAt(c3 & 0x3F);
      }
      return out;
    },
    copyEncry() {
      if (this.generateResult === '') {
        this.$message.error('请先生成UUID后再复制');
        return;
      }
      copyText(this.generateResult, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    del() {
      this.strUpperOrLower=false;
      this.delimiter=true;
      this.needSymbol=false;
      this.toBase64=false;
      this.generationLength=1;
      this.generateResult = '';
    }
  }
}
</script>

<style scoped lang="scss">
.aes_textarea {
  :deep(.el-textarea__inner) {
    height: calc(100vh - 205px);
  }

  margin: 10px 20px 0 20px;

  .aes-tabs-pane-span {
    font-size: 13px;
  }

  .aes-tabs-pane-input {
    margin: 5px 0 10px 0;
  }

  .bloder {
    font-weight: bolder;
    font-size: 16px;
  }

  .encry-decry-result {
    margin-top: 20px;
  }

  .aes-type {
    padding-right: 10px;
  }
}

.main-title {
  /*padding: 5px 0 0 10px;*/
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

//.aes_textarea{
//  :deep(.el-tabs__item.is-top){
//    width: 200px;
//    font-size: 20px;
//    color: red;
//  }
//  :deep(.el-tabs__active-bar.is-top){
//    background-color: green;
//  }
//}

</style>