<template>
  <div>
    <TopMainTitle/>
    <div class="aes_textarea">
      <div style="padding: 10px;font-size: 15px;border-radius: 4px;background: #dff0d8;color: #3c763d;margin: 10px 0;text-align: center">
        今天是{{nowTime}},{{weekTime}}，最近的节日是{{holidayTime}}的端午节，还有{{fromNowOn}}天。
      </div>
      <div class="holiday_list">
        <el-table :data="holidayList" border style="width: 100%">
          <el-table-column prop="yearMonthDay" label="日期" width="180" />
          <el-table-column prop="holidayName" label="节假日" width="180" />
          <el-table-column label="距今">
            <template #default="scope">
              <el-button
                  size="small"
                  :type="getButtonType(scope.row.fromNowOn)">
                {{scope.row.fromNowOn}}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import TopMainTitle from "@/components/TopMainTitle.vue";
import {queryHolidayList} from "@/utils/method";

export default {
  name: "nowYearHoliday",
  components: {TopMainTitle},
  data() {
    return {
      holidayList:[],
      buttonText:'',
      nowTime:'',
      weekTime:'',
      holidayName:'',
      holidayTime:'',
      fromNowOn:''
    };
  },
  mounted() {
    this.queryHolidayList();
  },
  methods: {
    getButtonType(fromNowOn){
      if(fromNowOn.indexOf('已过') !== -1){
        return 'info';
      }else if(fromNowOn.indexOf('还有') !== -1){
        return 'success'
      }else{
        return 'danger'
      }
    },
    queryHolidayList(){
      queryHolidayList().then(res=>{
        if(res.data.code === 200){
          //节假日
          this.holidayList = res.data.data.holidayList
          this.holidayList.forEach(item=>{
            console.log('-->item',item.fromNowOn)
            if(item.fromNowOn.indexOf('-') !== -1){
              item.fromNowOn = '已过 '+item.fromNowOn.replace('-','')+' 天';
            }else if(item.fromNowOn.indexOf('+') !== -1){
              item.fromNowOn = '还有 '+item.fromNowOn.replace('+','')+' 天';
            }else{
              item.fromNowOn = '今天';
            }
          });
          //顶部数据
          const closeRange = res.data.data.closeRange;
          this.nowTime = closeRange.yearMonthDay.replace('-','年').replace('-','月')+'日';
          this.weekTime = closeRange.weekTime;
          this.holidayName = closeRange.holidayName;
          this.holidayTime = closeRange.holidayTime.replace('-','年').replace('-','月')+'日';;
          this.fromNowOn = closeRange.fromNowOn.replace('+','');
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.aes_textarea {
  :deep(.el-textarea__inner) {
    height: calc(100vh - 205px);
  }
  margin: 10px 20px 0 20px;
  .holiday_list{
    display: flex;
    justify-content: center;
  }
}

</style>