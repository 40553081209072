<template>
  <div>
    <TopMainTitle/>
    <el-row>
      <el-col :span="12">
        <div style="padding: 10px">
          <el-input class="md5_textarea" type="textarea" resize="none" placeholder="请输入需要转换为图片的Base64编码" v-model="base64Value"></el-input>
        </div>
      </el-col>

      <el-col :span="12">
        <div class="base64_image">
          <a class="g_img" style="color: #959393;display: flex;justify-content: center;align-items: center"><span style="color: red;padding-right: 5px;padding-top: 3px">*</span>格式化后的图片<span style="font-size: 10px">（右击“图片另存为”保存到本地）</span></a>
          <el-image style="display: flex;justify-content: center;" v-if="imageIsShow" :src="imageValue"/>
        </div>
      </el-col>

    </el-row>
    <div style="text-align: center;margin-top: 10px;">
      <el-button style="width: 20%" type="primary" @click="conversion">转换</el-button>
      <el-button style="width: 20%" type="warning" @click="delValue">清空</el-button>
    </div>
  </div>
</template>

<script>

import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "base64ToImage",
  components: {TopMainTitle},
  data() {
    return {
      itemName:"",
      base64Value:'',
      imageValue:'',
      imageIsShow: false,
    };
  },
  methods: {
    conversion(){
      if(this.base64Value === ''){
        this.$message.error('请输入需要转换为图片的Base64编码！');
        return;
      }
      if(this.base64Value.indexOf('data:image/') !== -1){
        this.imageValue = this.base64Value;
      }else{
        this.imageValue = 'data:image/png;base64,'+this.base64Value;
      }
      this.imageIsShow = true;
    },
    delValue() {
      this.base64Value='';
      this.imageValue='';
      this.imageIsShow=false;
    },
  }
}
</script>

<style scoped lang="scss">
.md5_textarea{
  :deep(.el-textarea__inner){
    height: calc(100vh - 205px);
    font-family: inherit;
  }
}
.main-title{
  /*padding: 5px 0 0 10px;*/
  padding-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.base64_image{
  margin: 10px 10px 0 0;
  padding: 10px 10px 10px 0;
  height: calc(100vh - 205px);
  font-family: inherit;
  border: 1px solid #DDDFE6;
  border-radius: 4px;
  &:hover{
    border: 1px solid #C8CBD2;
  }
}
</style>