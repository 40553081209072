<template>
  <div>
    <TopMainTitle/>
    <el-row>
      <el-col :span="12">
        <div class="image_upload">
          <div class="id-card-uploader">
            <el-upload
                action="#"
                class="avatar-uploader"
                list-type="picture"
                ref="upload"
                accept=".jpg, .png, .jpeg, .bmp"
                :auto-upload="false"
                :show-file-list="false"
                :file-list="fileList"
                :on-change="getFile"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" alt=""/>
              <el-icon v-else class="avatar-uploader-icon">
                <Plus/>
              </el-icon>
            </el-upload>
            <div slot="tip" class="el-upload__tip">
              <em>请上传需要转换为base64的<span style="color: red">图片</span></em>
              <pre>只能上传jpg/png文件，且不超过4MB</pre>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="12">
        <div style="padding: 10px">
          <el-input class="md5_textarea" type="textarea" resize="none" placeholder="转换后的Base64编码" v-model="base64Value"></el-input>
        </div>
      </el-col>

    </el-row>
    <div style="text-align: center;margin-top: 10px;">
      <el-button style="width: 20%" type="primary" @click="conversion">转换</el-button>
      <el-button style="width: 20%" type="warning" @click="delValue">清空</el-button>
    </div>
  </div>
</template>

<script>

import {Plus} from "@element-plus/icons-vue";
import {ElMessage} from "element-plus";
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "imageToBase64",
  components: {Plus,TopMainTitle},
  data() {
    return {
      itemName:"",
      base64Value:'',
      imageValue:'',
      imageIsShow: false,
      imageBase64:'',
      imageUrl:'',
      fileList:[],
    };
  },
  methods: {
    getFile(file){
      //判断文件名称的后缀名是否是jpg、png、jpeg、bmp
      const fileType = ['jpg', 'png', 'jpeg', 'bmp', 'JPG', 'PNG', 'JPEG', 'BMP'];
      const fileName = file.name;
      const fileSplit = fileName.split('.');
      const fileSuffix = fileSplit[fileSplit.length - 1];
      if (!fileType.includes(fileSuffix)) {
        ElMessage.error('上传图片只能是 JPG、PNG、JPEG、BMP 格式!');
        return
      }
      if (this.beforeAvatarUpload(file)) {
        ElMessage.error('上传图片大小不能超过 4MB!');
        return
      }
      //如果fileList有值，清空
      if (this.fileList) {
        this.fileList = []
      }
      this.getBase64(file.raw).then((res) => {
        this.imageUrl = res;
        this.imageBase64 = res;
      });
    },
    getBase64(file){
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let imgResult = '';
        reader.readAsDataURL(file);
        reader.onload = () => {
          imgResult = reader.result;
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.onloadend = () => {
          resolve(imgResult);
        };
      });
    },
    conversion(){
      if(this.imageBase64 === ''){
        this.$message.error('请输入需要转换为base64的图片！');
        return;
      }
      this.base64Value = this.imageBase64;
    },
    delValue() {
      this.imageBase64 = '';
      this.base64Value = '';
      this.imageUrl = '';
    },
    beforeAvatarUpload(file){
      return file.size / 1024 / 1024 > 4;
    },
  }
}
</script>

<style scoped lang="scss">
.md5_textarea{
  :deep(.el-textarea__inner){
    height: calc(100vh - 205px);
    font-family: inherit;
  }
}
.main-title{
  /*padding: 5px 0 0 10px;*/
  padding-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.image_upload{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 10px;
  height: calc(100vh - 205px);
  font-family: inherit;
  border: 1px solid #DDDFE6;
  border-radius: 4px;
  &:hover{
    border: 1px solid #C8CBD2;
  }
}
.id-card-uploader {
  display: flex;
  flex-direction: column;
}

.avatar-uploader {
  :deep(.el-upload) {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
  }
}

.avatar-uploader :deep(.el-upload:hover) {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.avatar {
  width: 230px;
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>