<template>
  <div>
   <div class="nav">
      <div class="nav_left">
        <el-icon size="25"><Suitcase /></el-icon>
        <div class="titleContent">在线工具箱</div>

        <div class="tool_num">{{toolNum}}</div>
      </div>
      <div class="nav_right">
<!--        <el-icon><ChatLineSquare /></el-icon>
        <div class="msg_feedback">留言反馈</div>-->
<!--        <el-icon><User /></el-icon>
        <div class="register">未登录</div>-->
      </div>
   </div>


  </div>
</template>

<script>

import {getToolNum} from "@/utils/method";

export default {
  name: 'Title',
  data() {
    return {
      toolNum:'',
    };
  },
  mounted() {
    this.toolNum = getToolNum();
  },
}
</script>

<style scoped lang="scss">
.nav{
  width: 100%;
  background-color: #fff;
  height:40px;
  display: flex;
  justify-content: space-around;
  .titleContent{
    font-size: 22px;
    font-weight: bold;
    color: #595A59;
    padding-left: 10px;
  }
  .tool_num{
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
    color: #FF888A;
  }
  .nav_left{
    display: flex;
    align-items: center;
  }
  .nav_right{
    display: flex;
    align-items: center;
    .msg_feedback{
      font-size: 16px;
      line-height: 24px;
      color: #595A59;
      width: auto;
      text-align: center;
      cursor: pointer;
      margin-left: auto;
      margin-right: 30px;
      padding-left: 5px;
      font-weight: bold;
    }
    .register{
      font-size: 16px;
      line-height: 24px;
      color: rgb(4, 27, 15);
      width: auto;
      text-align: center;
      cursor: pointer;
      margin-left: auto;
      font-weight: bold;
      padding-left: 5px;
    }
    /*.register:hover{
      background-color: rgb(115, 209, 60);
      transition: background-color 0.1s ease-in-out 0s;
    }*/
  }
}
.dialog-footer{
  display: flex;
  justify-content: space-around;
}
</style>