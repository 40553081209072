<template>
  <div>
    <TopMainTitle/>
    <div class="aes_textarea">
      <div>
        <span class="aes-tabs-pane-span">需要替换的文本</span>
        <el-input
            v-model="beforeValue"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 6}"
            class="aes-tabs-pane-input"
            resize="none"
            placeholder="需要替换的文本"/>

        <span class="aes-tabs-pane-span">查找目标</span>
        <el-input
            v-model="findStr"
            type="input"
            class="aes-tabs-pane-input"
            placeholder="请输入查找内容"/>

        <span class="aes-tabs-pane-span">替换为</span>
        <el-input
            v-model="replaceStr"
            type="input"
            class="aes-tabs-pane-input"
            placeholder="请输入替换内容"/>

      </div>
      <div style="text-align: center;">
        <el-button style="width: 20%" type="primary" @click="textReplace">替换</el-button>
        <el-button style="width: 20%;color: white" color="#09BF8C" @click="copyDecry">复制结果</el-button>
        <el-button style="width: 20%" type="warning" @click="del">清空</el-button>
      </div>
      <div class="encry-decry-result">
        <span class="aes-tabs-pane-span">替换后的文本</span>
        <el-input
            v-model="afterValue"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 6}"
            class="aes-tabs-pane-input"
            resize="none"
            placeholder="替换后的文本"/>
      </div>
    </div>
  </div>
</template>

<script>
import {copyText} from "vue3-clipboard";
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "textReplaceAll",
  components: {TopMainTitle},
  data() {
    return {
      beforeValue:'',
      afterValue: '',
      findStr:'',
      replaceStr:'',
    };
  },
  methods: {
    textReplace(){
      if(this.beforeValue === ''){
        this.$message.error('请输入需要替换的文本');
        return;
      }
      this.afterValue = this.beforeValue.replaceAll(this.findStr, this.replaceStr);
    },
    copyDecry() {
      if(this.afterValue === ''){
        this.$message.error('请先替换文本后再复制');
        return;
      }
      copyText(this.afterValue, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    del(){
      this.beforeValue='';
      this.afterValue='';
      this.findStr='';
      this.replaceStr='';
    },
  }
}
</script>

<style scoped lang="scss">
.aes_textarea{
  :deep(.el-textarea__inner){
    height: calc(100vh - 205px);
  }
  margin: 0 20px 0 20px;
  .aes-tabs-pane-span{
    font-size: 13px;
  }
  .aes-tabs-pane-input{
    margin: 5px 0 25px 0;
  }
  .encry-decry-result{
    margin-top: 25px;
  }
  .aes-type{
    padding-right: 10px;
  }
}
.main-title{
  /*padding: 5px 0 0 10px;*/
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

//.aes_textarea{
//  :deep(.el-tabs__item.is-top){
//    width: 200px;
//    font-size: 20px;
//    color: red;
//  }
//  :deep(.el-tabs__active-bar.is-top){
//    background-color: green;
//  }
//}

</style>