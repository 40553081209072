<template>
  <div>
    <div class="main-title">{{itemName}}</div>
  </div>
</template>

<script>
import { updateReadCount,getItemName } from '@/utils/method'

export default {
  name: "topMainTitle",
  data() {
    return {
      itemName:"",
    };
  },
  mounted() {
    this.itemName = getItemName(this.$route.name);
    this.updateReadCountByItemUrl();
  },
  methods: {
    updateReadCountByItemUrl(){
      updateReadCount(this.$route.name);
    }
  }
}
</script>

<style scoped lang="scss">
.main-title{
  /*padding: 5px 0 0 10px;*/
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
</style>