<template>
  <div>
    <TopMainTitle/>
    <div class="aes_textarea">
      <div class="div_select">
        <el-tooltip effect="dark" placement="bottom">
          <svg style="vertical-align: middle ;margin-right: 5px;cursor: pointer;margin-top: 5px" t="1687222561366" class="icon"
               viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2236"
               data-spm-anchor-id="a313x.7781069.0.i0" width="15" height="15">
            <path
                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                p-id="2237" fill="#98a1a8"></path>
            <path
                d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7c0-19.7 12.4-37.7 30.9-44.8 59-22.7 97.1-74.7 97.1-132.5 0.1-39.3-17.1-76-48.3-103.3z"
                p-id="2238" fill="#98a1a8"></path>
            <path d="M512 732m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" p-id="2239" fill="#98a1a8"></path>
          </svg>
          <template #content>
            <div style="width: 200px">
              • 仅支持查询1900年至2100年之间的节气
              <br/>
            </div>
          </template>
        </el-tooltip>
        <el-select v-model="selectYear" placeholder="请选择年份" style="width: 240px;margin-right: 10px">
          <el-option
              v-for="item in yearOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
        <el-button type="primary" @click="serachInput">搜索</el-button>
      </div>
      <div class="chun_cls">
        <p class="chun_p_cls">春</p>
        <el-card class="chun_el_card_cls" v-for="item of solarTermsList.a">
          <div style="display: flex;justify-content: center;font-size: 13px;font-weight: bold">
            <p>{{item.solarTerms}}</p>
          </div>
          <div style="display: flex;justify-content: center;font-size: 11px">
            <p>{{item.yearMonthDay}}</p>
          </div>
          <div style="display: flex;justify-content: center;font-size: 11px">
            {{item.hourMinuteSecond}}
          </div>
          <div style="display: flex;justify-content: center;font-size: 10px">
            农历:{{item.lunarDate}}
          </div>
        </el-card>
      </div>
      <div class="xia_cls">
        <p class="chun_p_cls">夏</p>
        <el-card class="xia_el_card_cls" v-for="item of solarTermsList.b">
          <div style="display: flex;justify-content: center;font-size: 13px;font-weight: bold">
            <p>{{item.solarTerms}}</p>
          </div>
          <div style="display: flex;justify-content: center;font-size: 11px">
            <p>{{item.yearMonthDay}}</p>
          </div>
          <div style="display: flex;justify-content: center;font-size: 11px">
            {{item.hourMinuteSecond}}
          </div>
          <div style="display: flex;justify-content: center;font-size: 10px">
            农历:{{item.lunarDate}}
          </div>
        </el-card>
      </div>
      <div class="qiu_cls">
        <p class="chun_p_cls">秋</p>
        <el-card class="qiu_el_card_cls" v-for="item of solarTermsList.c">
          <div style="display: flex;justify-content: center;font-size: 13px;font-weight: bold">
            <p>{{item.solarTerms}}</p>
          </div>
          <div style="display: flex;justify-content: center;font-size: 11px">
            <p>{{item.yearMonthDay}}</p>
          </div>
          <div style="display: flex;justify-content: center;font-size: 11px">
            {{item.hourMinuteSecond}}
          </div>
          <div style="display: flex;justify-content: center;font-size: 10px">
            农历:{{item.lunarDate}}
          </div>
        </el-card>
      </div>
      <div class="dong_cls">
        <p class="chun_p_cls">冬</p>
        <el-card class="dong_el_card_cls" v-for="item of solarTermsList.d">
          <div style="display: flex;justify-content: center;font-size: 13px;font-weight: bold">
            <p>{{item.solarTerms}}</p>
          </div>
          <div style="display: flex;justify-content: center;font-size: 11px">
            <p>{{item.yearMonthDay}}</p>
          </div>
          <div style="display: flex;justify-content: center;font-size: 11px">
            {{item.hourMinuteSecond}}
          </div>
          <div style="display: flex;justify-content: center;font-size: 10px">
            农历:{{item.lunarDate}}
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import TopMainTitle from "@/components/TopMainTitle.vue";
import {checkIsNull, getYearOptions, queryNowYear, querySolarTermsListByYear} from "@/utils/method";

export default {
  name: "twentyFourSolarTerms",
  components: {TopMainTitle},
  data() {
    return {
      solarTermsList:[],
      selectYear:'',
      yearOptions:[],
    };
  },
  mounted() {
    this.yearOptions = getYearOptions();
    this.querySolarTermsList(queryNowYear(new Date()));
  },
  methods: {
    serachInput(){
      const year = this.selectYear;
      if(checkIsNull(year)){
        this.$message.error('请输入需要查询节气的年份！');
        return;
      }
      if(year > 2100 || year < 1900){
        this.$message.error('仅支持查询1900年至2100年之间的节气！');
        return;
      }
      this.querySolarTermsList(year);
    },
    querySolarTermsList(year){
      querySolarTermsListByYear(year).then(res=>{
        if(res.data.code === 200){
          this.solarTermsList = res.data.data;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.aes_textarea {
  :deep(.el-textarea__inner) {
    height: calc(100vh - 205px);
  }
  margin: 10px 20px 0 20px;
  .holiday_list{
    display: flex;
    justify-content: center;
  }
  .chun_cls{
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 20px;
    margin-bottom: 30px;
    background-color: #EDFACD;
    border-radius: 10px;
    color: #669900;
  }
  .xia_cls{
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #FDE5E2;
    border-radius: 10px;
    color: #CC3300;
  }
  .qiu_cls{
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #FAF0D3;
    border-radius: 10px;
    color: #FF6600;
  }
  .dong_cls{
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #C9E7D4;
    border-radius: 10px;
    color: #53AA85;
  }
  .chun_el_card_cls{
    height:80px;
    width:190px;
    background-color: #F8FDE6;
    color: #669900;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .chun_p_cls{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px
  }
  .xia_el_card_cls{
    height:80px;
    width:190px;
    background-color: #FDEEEC;
    color: #CC3300;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .qiu_el_card_cls{
    height:80px;
    width:190px;
    background-color: #FDF8E6;
    color: #FF6600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .dong_el_card_cls{
    height:80px;
    width:190px;
    background-color: #D5EFE0;
    color: #53AA85;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .div_select{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>