<template>
  <div>
    <TopMainTitle/>
    <div class="aes_textarea">
      <div>
        <span class="aes-tabs-pane-span">加解密前的字符串</span>
        <el-input
            v-model="beforeValue"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4}"
            class="aes-tabs-pane-input"
            resize="none"
            placeholder="请输入需要加密的字符串"/>

        <span class="aes-tabs-pane-span">密钥</span>
        <el-input
            v-model="aesKey"
            type="input"
            class="aes-tabs-pane-input"
            :maxlength="maxLength"
            placeholder="请输入密钥（建议16、24、32位字节）"/>

        <span class="aes-tabs-pane-span" v-show="ivValueIsShow">偏移量IV</span>
        <el-input
            v-model="ivValue"
            type="input"
            class="aes-tabs-pane-input"
            v-show="ivValueIsShow"
            :maxlength="maxLength"
            placeholder="请输入偏移量IV（建议16、24、32位字节）"/>

        <span class="aes-tabs-pane-span aes-type">模式：</span>
        <el-radio-group v-model="aesType" class="aes-tabs-pane-input" @change="aesTypeChange">
          <el-radio :label="1">CBC</el-radio>
          <el-radio :label="2">CFB</el-radio>
          <el-radio :label="3">CTR</el-radio>
          <el-radio :label="4">ECB</el-radio>
          <el-radio :label="5">OFB</el-radio>
        </el-radio-group>
        <div>
          <span class="aes-tabs-pane-span aes-type">填充：</span>
          <el-radio-group v-model="aesFill" class="aes-tabs-pane-input" @change="aesFillChange">
            <el-radio :label="1">Pkcs7</el-radio>
            <el-radio :label="2">ZeroPadding</el-radio>
            <el-radio :label="3">AnsiX923</el-radio>
            <el-radio :label="4">Iso10126</el-radio>
            <el-radio :label="5">Iso97971</el-radio>
            <el-radio :label="6">NoPadding</el-radio>
          </el-radio-group>
        </div>
        <div>
          <span class="aes-tabs-pane-span aes-type">密钥长度：</span>
          <el-radio-group v-model="aesKeyLength" class="aes-tabs-pane-input" @change="aesKeyLengthChange">
            <el-radio :label="1">128bit(16字符)</el-radio>
            <el-radio :label="2">192bit(24字符)</el-radio>
            <el-radio :label="3">256bit(32字符)</el-radio>
          </el-radio-group>
        </div>
        <div>
          <span class="aes-tabs-pane-span aes-type">编码：</span>
          <el-radio-group v-model="aesCode" class="aes-tabs-pane-input" @change="aesCodeChange">
            <el-radio :label="1">Base64</el-radio>
            <el-radio :label="2">Hex</el-radio>
          </el-radio-group>
        </div>

      </div>
      <div style="text-align: center;">
        <el-button style="width: 20%" type="primary" @click="aesEncry">加密</el-button>
        <el-button style="width: 20%" type="success" @click="aesDecry">解密</el-button>
        <el-button style="width: 20%;color: white" color="#09BF8C" @click="copyEncry">复制结果</el-button>
        <el-button style="width: 20%" type="warning" @click="del">清空</el-button>
      </div>
      <div class="encry-decry-result">
        <span class="aes-tabs-pane-span bloder">加解密后的字符串</span>
        <el-input
            v-model="afterValue"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4}"
            class="aes-tabs-pane-input"
            resize="none"
            placeholder="加解密后的字符串"/>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js';
import {copyText} from 'vue3-clipboard'
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "aesEncryDecry",
  components: {TopMainTitle},
  data() {
    return {
      itemName:"",
      beforeValue:"",
      afterValue:"",
      aesKey: "",
      ivValue: "",
      aesType:1,
      ivValueIsShow: true,
      aesFill:1,
      aesCode:1,
      maxLength:16,
      aesKeyLength:1
    };
  },
  methods: {
    aesTypeChange(){
      if(this.aesType === 4){
        this.ivValueIsShow = false;
      }else{
        this.ivValueIsShow = true;
      }
      this.beforeValue = '';
      this.afterValue = '';
    },
    aesFillChange(){
      this.beforeValue = '';
      this.afterValue = '';
    },
    aesCodeChange(){
      this.beforeValue = '';
      this.afterValue = '';
    },
    aesKeyLengthChange(){
      this.beforeValue = '';
      this.afterValue = '';
      if(this.aesKeyLength === 1){
        this.maxLength = 16;
      }else if(this.aesKeyLength === 2){
        this.maxLength = 24;
      }else if(this.aesKeyLength === 3){
        this.maxLength = 32;
      }
    },
    aesEncry() {
      if(this.beforeValue === ''){
        this.$message.error('请输入需要加密的字符串');
        return;
      }
      if(this.aesType !== 4){
        if(this.aesKeyLength === 1){
          if(this.aesKey.length !== 16 || this.ivValue.length !== 16){
            this.$message.error('请输入16位字符的密钥或偏移量');
            return;
          }
        }else if(this.aesKeyLength === 2){
          if(this.aesKey.length !== 24 || this.ivValue.length !== 24){
            this.$message.error('请输入24位字符的密钥或偏移量');
            return;
          }
        }else if(this.aesKeyLength === 3){
          if(this.aesKey.length !== 32 || this.ivValue.length !== 32){
            this.$message.error('请输入32位字符的密钥或偏移量');
            return;
          }
        }
      }else{
        if(this.aesKeyLength === 1){
          if(this.aesKey.length !== 16){
            this.$message.error('请输入16位字符的密钥');
            return;
          }
        }else if(this.aesKeyLength === 2){
          if(this.aesKey.length !== 24){
            this.$message.error('请输入24位字符的密钥');
            return;
          }
        }else if(this.aesKeyLength === 3){
          if(this.aesKey.length !== 32){
            this.$message.error('请输入32位字符的密钥');
            return;
          }
        }
      }

      this.afterValue = '';
      let mode = this.getAesType();
      let padding = this.getAesFill();
      let srcs = CryptoJS.enc.Utf8.parse(this.beforeValue);
      let encryptValue = "";
      if(this.aesType === 4){
        encryptValue = CryptoJS.AES.encrypt(srcs, CryptoJS.enc.Utf8.parse(this.aesKey), { mode: mode, padding: padding })
      }else{
        encryptValue = CryptoJS.AES.encrypt(srcs, CryptoJS.enc.Utf8.parse(this.aesKey), { iv: CryptoJS.enc.Utf8.parse(this.ivValue), mode: mode, padding: padding });
      }
      if(this.aesCode === 2){
        this.afterValue = encryptValue.ciphertext.toString().toUpperCase()
      }else{
        this.afterValue = CryptoJS.enc.Base64.stringify(encryptValue.ciphertext);
      }
    },
    aesDecry() {
      if(this.beforeValue === ''){
        this.$message.error('请输入需要解密的字符串');
        return;
      }
      if(this.aesType !== 4){
        if(this.aesKeyLength === 1){
          if(this.aesKey.length !== 16 || this.ivValue.length !== 16){
            this.$message.error('请输入16位字符的密钥或偏移量');
            return;
          }
        }else if(this.aesKeyLength === 2){
          if(this.aesKey.length !== 24 || this.ivValue.length !== 24){
            this.$message.error('请输入24位字符的密钥或偏移量');
            return;
          }
        }else if(this.aesKeyLength === 3){
          if(this.aesKey.length !== 32 || this.ivValue.length !== 32){
            this.$message.error('请输入32位字符的密钥或偏移量');
            return;
          }
        }
      }else{
        if(this.aesKeyLength === 1){
          if(this.aesKey.length !== 16){
            this.$message.error('请输入16位字符的密钥');
            return;
          }
        }else if(this.aesKeyLength === 2){
          if(this.aesKey.length !== 24){
            this.$message.error('请输入24位字符的密钥');
            return;
          }
        }else if(this.aesKeyLength === 3){
          if(this.aesKey.length !== 32){
            this.$message.error('请输入32位字符的密钥');
            return;
          }
        }
      }

      try {
        let mode = this.getAesType();
        let padding = this.getAesFill();
        this.decryValue = '';
        let decrypt = '';
        console.log('this.aesType',this.aesType)
        console.log('this.aesCode',this.aesCode)
        if(this.aesType === 4){
          if(this.aesCode === 2){
            decrypt = decrypt = CryptoJS.AES.decrypt(CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(this.beforeValue)), CryptoJS.enc.Utf8.parse(this.aesKey), { mode: mode, padding: padding });
          }else{
            decrypt = CryptoJS.AES.decrypt(CryptoJS.enc.Base64.stringify(CryptoJS.enc.Base64.parse(this.beforeValue)), CryptoJS.enc.Utf8.parse(this.aesKey), { mode: mode, padding: padding });
          }
        }else{
          if(this.aesCode === 2){
            decrypt = CryptoJS.AES.decrypt(CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(this.beforeValue)), CryptoJS.enc.Utf8.parse(this.aesKey), { iv: CryptoJS.enc.Utf8.parse(this.ivValue), mode: mode, padding: padding });
          }else{
            decrypt = CryptoJS.AES.decrypt(CryptoJS.enc.Base64.stringify(CryptoJS.enc.Base64.parse(this.beforeValue)), CryptoJS.enc.Utf8.parse(this.aesKey), { iv: CryptoJS.enc.Utf8.parse(this.ivValue), mode: mode, padding: padding });
          }
        }
        let decryptValue = "";
        if(this.aesCode === 2){
          decryptValue = CryptoJS.enc.Utf8.stringify(decrypt);
        }else{
          decryptValue = CryptoJS.enc.Utf8.stringify(decrypt);
        }

        if(decryptValue === ''){
          this.$message.error('解密失败');
        }else{
          this.afterValue = decryptValue;
        }
      }catch (e) {
        this.$message.error('解密失败');
      }
    },
    copyEncry() {
      if(this.afterValue === ''){
        this.$message.error('请先计算加解密数据后再复制');
        return;
      }
      copyText(this.afterValue, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    del(){
      this.beforeValue = '';
      this.afterValue='';
      this.ivValue = '';
      this.aesKey='';
      this.aesType = 1;
      this.aesFill = 1;
      this.ivValueIsShow = true;
      this.aesCode = 1;
      this.maxLength = 16;
      this.aesKeyLength = 1;
    },
    getAesType(){
      let mode = "";
      if(this.aesType === 1){
        mode = CryptoJS.mode.CBC;
      }else if(this.aesType === 2) {
        mode = CryptoJS.mode.CFB;
      }else if(this.aesType === 3) {
        mode = CryptoJS.mode.CTR;
      }else if(this.aesType === 4) {
        mode = CryptoJS.mode.ECB;
      }else if(this.aesType === 5) {
        mode = CryptoJS.mode.OFB;
      }
      return mode;
    },
    getAesFill(){
      let padding = "";
      if(this.aesType === 1){
        padding = CryptoJS.pad.Pkcs7;
      }else if(this.aesType === 2) {
        padding = CryptoJS.pad.ZeroPadding;
      }else if(this.aesType === 3) {
        padding = CryptoJS.pad.AnsiX923;
      }else if(this.aesType === 4) {
        padding = CryptoJS.pad.Iso10126;
      }else if(this.aesType === 5) {
        padding = CryptoJS.pad.Iso97971;
      }else if(this.aesType === 6) {
        padding = CryptoJS.pad.NoPadding;
      }
      return padding;
    }
  }
}
</script>

<style scoped lang="scss">
.aes_textarea{
  :deep(.el-textarea__inner){
    height: calc(100vh - 205px);
  }
  margin: 0 20px 0 20px;
  .aes-tabs-pane-span{
    font-size: 13px;
  }
  .aes-tabs-pane-input{
    margin: 5px 0 5px 0;
  }
  .bloder{
    font-weight: bolder;
    font-size: 16px;
  }
  .encry-decry-result{
    margin-top: 5px;
  }
  .aes-type{
    padding-right: 10px;
  }
}
.main-title{
  /*padding: 5px 0 0 10px;*/
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

//.aes_textarea{
//  :deep(.el-tabs__item.is-top){
//    width: 200px;
//    font-size: 20px;
//    color: red;
//  }
//  :deep(.el-tabs__active-bar.is-top){
//    background-color: green;
//  }
//}

</style>