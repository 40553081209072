<template>
  <div>
    <TopMainTitle/>
    <div class="aes_textarea">
      <div>
        <span class="aes-tabs-pane-span aes-type start_time">开启时间：</span>
        <div class="time_div">
          <el-input-number v-model="yearValue" style="width: 101px;margin-right: 10px" controls-position="right" />
          <span class="aes-tabs-pane-span aes-type">年</span>
          <el-select
              v-model="monthValue"
              placeholder="Select"
              class="el_select_cls">
            <el-option
                v-for="item in monthOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
          <span class="aes-tabs-pane-span aes-type">月</span>
          <el-select
              v-model="dayValue"
              placeholder="Select"
              class="el_select_cls">
            <el-option
                v-for="item in dayOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
          <span class="aes-tabs-pane-span aes-type">日</span>
          <el-select
              v-model="hourValue"
              placeholder="Select"
              class="el_select_cls">
            <el-option
                v-for="item in hourOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
          <span class="aes-tabs-pane-span aes-type">时</span>
          <el-select
              v-model="minuteValue"
              placeholder="Select"
              class="el_select_cls">
            <el-option
                v-for="item in minuteOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
          <span class="aes-tabs-pane-span aes-type">分</span>
          <el-select
              v-model="secondValue"
              placeholder="Select"
              class="el_select_cls">
            <el-option
                v-for="item in secondOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
          <span class="aes-tabs-pane-span aes-type">秒</span>
        </div>


        <span class="aes-tabs-pane-span aes-type">保质期限：</span>
        <div class="time_div">
          <el-input-number v-model="shelfLifeValue" style="width: 101px;margin-right: 10px" controls-position="right" />
          <el-select
              v-model="timeUnitValue"
              placeholder="Select"
              class="el_select_cls">
            <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>
      </div>

      <div style="text-align: center;margin-top: 20px">
        <el-button style="width: 20%" type="primary" @click="generateFun">计算</el-button>
        <el-button style="width: 20%;color: white" color="#09BF8C" @click="copyEncry">复制结果</el-button>
        <el-button style="width: 20%" type="warning" @click="del">重置</el-button>
      </div>
      <div class="encry-decry-result">
        <span class="aes-tabs-pane-span bloder">计算结果</span>
        <el-input
            v-model="afterValue"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4}"
            class="aes-tabs-pane-input"
            resize="none"
            placeholder="计算结果"/>
      </div>

    </div>
  </div>
</template>

<script>
import {copyText} from 'vue3-clipboard'
import TopMainTitle from "@/components/TopMainTitle.vue";
import {
  getDayOptions,
  getHourOptions,
  getMinuteOptions,
  getMonthOptions,
  getSecondOptions, getTimeOptions,
  gettimeOptions, queryNowTime
} from "@/utils/method";

export default {
  name: "shelfLifeCalculator",
  components: {TopMainTitle},
  data() {
    return {
      yearValue:'2024',
      monthValue:'05',
      dayValue:'07',
      hourValue:'09',
      minuteValue:'12',
      secondValue:'54',
      monthOptions:[],
      dayOptions:[],
      hourOptions:[],
      minuteOptions:[],
      secondOptions:[],
      shelfLifeValue:'7',
      timeUnitValue:'day',
      timeOptions:[],
      afterValue:'',
    };
  },
  mounted() {
    this.monthOptions = getMonthOptions();
    this.dayOptions = getDayOptions();
    this.hourOptions = getHourOptions();
    this.minuteOptions = getMinuteOptions();
    this.secondOptions = getSecondOptions();
    this.timeOptions = getTimeOptions();
    this.initTime();
  },
  methods: {
    initTime(){
      let date = new Date();
      this.yearValue = date.getFullYear().toString();
      this.monthValue = (date.getMonth() + 1).toString().padStart(2, '0');
      this.dayValue = date.getDate().toString().padStart(2, '0');
      this.hourValue = date.getHours().toString().padStart(2, '0');
      this.minuteValue = date.getMinutes().toString().padStart(2, '0');
      this.secondValue = date.getSeconds().toString().padStart(2, '0');
    },
    generateFun(){
      let selectTime = new Date(this.yearValue+"-"+this.monthValue+"-"+this.dayValue+"T"+this.hourValue+":"+this.minuteValue+":"+this.secondValue);
      if(this.timeUnitValue === 'year'){
        selectTime.setFullYear(selectTime.getFullYear() + parseInt(this.shelfLifeValue));
      }else if(this.timeUnitValue === 'month'){
        selectTime.setMonth(selectTime.getMonth() + parseInt(this.shelfLifeValue));
      }else if(this.timeUnitValue === 'day'){
        selectTime.setDate(selectTime.getDate() + parseInt(this.shelfLifeValue));
      }else if(this.timeUnitValue === 'hour'){
        selectTime.setHours(selectTime.getHours() + parseInt(this.shelfLifeValue));
      }else if(this.timeUnitValue === 'minute'){
        selectTime.setMinutes(selectTime.getMinutes() + parseInt(this.shelfLifeValue));
      }else if(this.timeUnitValue === 'second'){
        selectTime.setSeconds(selectTime.getSeconds() + parseInt(this.shelfLifeValue));
      }
      while (selectTime.getDate() > new Date(selectTime.getFullYear(), selectTime.getMonth() + 1, 0).getDate()) {
        selectTime.setMonth(selectTime.getMonth() + 1);
        selectTime.setDate(selectTime.getDate() - new Date(selectTime.getFullYear(), selectTime.getMonth() + 1, 0).getDate());
      }
      this.afterValue = "生产日期："+this.yearValue+"-"+this.monthValue+"-"+this.dayValue+" "+this.hourValue+":"+this.minuteValue+":"+this.secondValue+"\n失效日期："+queryNowTime(selectTime);
    },
    copyEncry() {
      if (this.afterValue === '') {
        this.$message.error('请先计算后再复制');
        return;
      }
      copyText(this.afterValue, undefined, (error) => {
        if (error) {
          this.$message.error('复制失败');
        } else {
          this.$message.success('复制成功！');
        }
      });
    },
    del() {
      this.monthOptions = getMonthOptions();
      this.dayOptions = getDayOptions();
      this.hourOptions = getHourOptions();
      this.minuteOptions = getMinuteOptions();
      this.secondOptions = getSecondOptions();
      this.timeOptions = getTimeOptions();
      this.initTime();
      this.afterValue = '';
    }
  }
}
</script>

<style scoped lang="scss">
.aes_textarea {
  :deep(.el-textarea__inner) {
    height: calc(100vh - 205px);
  }

  margin: 10px 20px 0 20px;

  .aes-tabs-pane-span {
    font-size: 13px;
  }

  .aes-tabs-pane-input {
    margin: 5px 0 10px 0;
  }

  .bloder {
    font-weight: bolder;
    font-size: 16px;
  }

  .encry-decry-result {
    margin-top: 20px;
  }

  .aes-type {
    padding-right: 10px;
  }
}

.main-title {
  /*padding: 5px 0 0 10px;*/
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.el_select_cls{
  width: 70px;
  margin-right: 10px;
}
.time_div{
  padding-top: 5px;
  padding-bottom: 10px;
}
//.aes_textarea{
//  :deep(.el-tabs__item.is-top){
//    width: 200px;
//    font-size: 20px;
//    color: red;
//  }
//  :deep(.el-tabs__active-bar.is-top){
//    background-color: green;
//  }
//}

</style>