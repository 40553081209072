<template>
  <TopMainTitle/>
  <div class="custom-file-upload-div">
    <label for="fileInput" class="custom-file-upload">
      点击上传图片
    </label>
  </div>
  <div class="nav_div_cls">
    <input
        type="file"
        id="fileInput"
        ref="fileInput"
        @change="handleFileChange"
        style="display: none;"
    />
    <canvas
        ref="canvas"
        v-if="imageUrl"
        @click="handleCanvasClick"
        class="image-canvas"
    ></canvas>
  </div>
  <div v-if="mainColor" class="color-display">
    <div>
      <a style="color:red;">*</a>
      <span>
        识别结果：{{ mainColor }}
      </span>
    </div>
    <div :style="{ backgroundColor: mainColor }" class="color-display-back"/>
  </div>
</template>

<script>
import TopMainTitle from "@/components/TopMainTitle.vue";

export default {
  name: "imageRecognitionColor",
  components: {TopMainTitle},
  data() {
    return {
      mainColor: null,
      imageUrl: null,
      canvasWidth: 500,
      canvasHeight: 500
    };
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.imageUrl = URL.createObjectURL(file);
        this.getImageDimensions();
      }
    },
    async handleCanvasClick(event) {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const imageData = ctx.getImageData(x, y, 1, 1).data;
      const rgbColor = `rgb(${imageData[0]}, ${imageData[1]}, ${imageData[2]})`;
      this.mainColor = rgbColor;
    },
    async getImageDimensions() {
      const image = new Image();
      image.src = this.imageUrl;
      await new Promise(resolve => {
        image.onload = () => {
          const canvas = this.$refs.canvas;

          // this.canvasWidth = image.width>700?700:image.width;
          // this.canvasHeight = image.height>500?500:image.height;
          this.canvasWidth = image.width;
          this.canvasHeight = image.height;

          canvas.width = this.canvasWidth;
          canvas.height = this.canvasHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, this.canvasWidth, this.canvasHeight);
          resolve();
        };
      });
    }
  },
  watch: {
    imageUrl() {
      if (this.imageUrl) {
        this.getImageDimensions();
      }
    }
  }
};
</script>

<style>
.nav_div_cls{
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 255px);
  border: 1px solid #DDDFE6;
}
.custom-file-upload {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
}
.custom-file-upload-div{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-display {
  display: flex;
  justify-content: center;
  margin: 10px 100px 0 100px;
  padding: 10px;
}
.color-display-back{
  margin-left: 20px;
  padding: 10px;
}
</style>
